import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as HybridCloudEnvironmentsRoute } from './_hybrid-cloud-environments';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { MIDDOT_SYMBOL } from '../utils/constants';

export const Route = createRoute({
  meta: () => [
    {
      title: `Hybrid Cloud ${MIDDOT_SYMBOL} Onboarding`,
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: 'onboarding',
  component: HybridCloudOnboardingComponent,
});

const LazyHybridCloudOnboarding = lazy(() =>
  import(
    /* webpackChunkName: "hybrid-cloud-onboarding" */ '../components/PrivateRegions/Onboarding/HybridCloudOnboarding'
  ).then(({ HybridCloudOnboarding }) => ({
    default: HybridCloudOnboarding,
  })),
);

function HybridCloudOnboardingComponent() {
  return (
    <AccountDashboardMain title="Hybrid Cloud">
      <Box sx={{ marginTop: 3 }}>
        <Suspense fallback={<LoadingIndicator />}>
          <LazyHybridCloudOnboarding />
        </Suspense>
      </Box>
    </AccountDashboardMain>
  );
}
