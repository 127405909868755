import { useQuery } from '@tanstack/react-query';
import { useState, ReactNode, useEffect, createContext, useContext, useMemo } from 'react';
import { featureFlagsQuery } from '../../api/services/core';
import { useTrackPageView } from '../../hooks/use-event-tracking';

type EnqueuedCallback = (timestamp?: Date /* when the event took place */) => unknown;

const AnalyticsContext = createContext<{
  enqueueEvent: (callback: EnqueuedCallback) => void;
  allowedAnalytics: boolean;
}>({ allowedAnalytics: false, enqueueEvent: (_) => {} });

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children, disabled = false }: { children: ReactNode; disabled?: boolean }) => {
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  const allowedAnalytics = !disabled && analyticsLoaded;
  const { data: featureFlagData } = useQuery({ ...featureFlagsQuery, enabled: allowedAnalytics });

  useEffect(() => {
    function handleAnalyticsLoaded() {
      setAnalyticsLoaded(true);
    }
    document.addEventListener('gtm_analytics_loaded', handleAnalyticsLoaded, { once: true });
    return () => document.removeEventListener('gtm_analytics_loaded', handleAnalyticsLoaded);
  }, []);

  const [eventQueue] = useState(() => {
    let dispatched = false;
    const queue = new Set<[EnqueuedCallback, Date]>();
    return {
      set dispatchQueue(value: boolean) {
        if (value) {
          queue.forEach(([callback, timestamp]) => {
            callback(timestamp);
          });
          queue.clear();
        }
        dispatched = value;
      },
      enqueue: (callback: EnqueuedCallback) => {
        if (dispatched) {
          callback();
        } else {
          queue.add([callback, new Date()]);
        }
      },
    };
  });

  useMemo(() => {
    if (!featureFlagData) {
      return;
    }
    const featureFlags = Object.entries(featureFlagData).reduce<Record<`ff_${string}`, boolean>>(
      (acc, [key, value]) => {
        acc[`ff_${key}`] = value;
        return acc;
      },
      {},
    );
    eventQueue.enqueue(() =>
      window.analytics.identify(null, null, { context: { ...featureFlags, version: window.__QDRANT_CLOUD__.version } }),
    );
  }, [featureFlagData, eventQueue]);

  const value = useMemo(() => {
    eventQueue.dispatchQueue = allowedAnalytics;
    return { enqueueEvent: eventQueue.enqueue, allowedAnalytics };
  }, [allowedAnalytics, eventQueue]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export function AnalyticsRoot({ children }: { children: ReactNode }) {
  const trackPageView = useTrackPageView();
  // SPA Page View Logic
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return children;
}
