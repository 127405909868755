import DeleteIcon from '@mui/icons-material/Delete';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { Box, Skeleton, SxProps, Typography, Link, Tooltip, Card, CardHeader } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { format, isBefore } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InstantBackupButton } from './InstantBackupButton';
import { BACKUPS_DATE_FORMAT } from './constants';
import { useConfirmationAction } from '../../../hooks/use-confirmation-action';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { useWindowFocus } from '../../../hooks/use-window-focus';
import { useAccountId } from '../../../routes/_account';
import {
  useDeleteClusterBackupMutation,
  useGetBackupsQuery,
  useRestoreClusterBackupMutation,
} from '../../../services/clustersApi';
import { isFetchMutationError } from '../../../services/helpers';
import { Backup, Cluster } from '../../../utils/cluster-utils';
import { CardTitleWithLoadingIndicator } from '../../Common/CardTitleWithLoadingIndicator';
import { ConfirmationDialog } from '../../Common/ConfirmationDialog';
import { ErrorBox } from '../../Common/ErrorBox';
import { useOnlineConnection } from '../../Common/NetworkProvider';
import { ColumnType, QdrantTable, RowAction, RowType } from '../../Common/QdrantTable';
import { Scrollbar } from '../../Common/Scrollbar';
import { SeverityPill } from '../../Common/SeverityPill';

type BackupsTableProps = {
  defaultCluster?: Cluster;
  loadingClusters?: boolean;
  availableClusters?: Cluster[];
  sx?: SxProps;
};

type BackupStatus = Backup['status'];

type BackupInfo = {
  name: string;
  clusterId: string;
  createdAt: string;
  deletedAt?: string | null;
  status: BackupStatus;
  clusterExists: boolean;
  clusterName: string;
};

const FEEDBACK_MSG_CONTAINER_STYLE = {
  pl: 3,
  pb: 3,
};

const BACKUPS_COLUMNS = [
  { title: 'Cluster', dataKey: 'clusterName' },
  { title: 'Name', dataKey: 'name' },
  { title: 'Creation', dataKey: 'creation' },
  { title: 'Status', dataKey: 'status', align: 'center' as const },
  { title: 'Created at', dataKey: 'creationTimestamp', align: 'center' as const },
];

const getStatusColor = (status: BackupStatus) => {
  switch (status) {
    case 'Running':
      return 'warning';
    case 'Succeeded':
      return 'success';
    case 'Failed':
      return 'error';
    case 'Skipped':
    default:
      return 'inactive';
  }
};

const BackupsTableContainer = ({
  rows,
  columns,
  actions,
}: {
  rows: RowType<BackupInfo>[];
  columns: ColumnType<BackupInfo>[];
  actions: RowAction<BackupInfo>[];
}) =>
  rows.length === 0 ? (
    <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
      <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
        No existing backups.
      </Typography>
    </Box>
  ) : (
    <QdrantTable<BackupInfo>
      columns={columns}
      rows={rows}
      rowActions={actions}
      order={['createdAt', 'desc']}
      pagination={true}
      ariaLabel="Backups"
    />
  );

export function BackupsTable({ defaultCluster, loadingClusters, availableClusters, sx }: BackupsTableProps) {
  const accountId = useAccountId();
  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();
  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isAllClustersBackups = !defaultCluster;
  const [backupsList, setBackupsList] = useState<Backup[]>();

  const {
    data: backups,
    isLoading: loadingBackups,
    isFetching: fetchingBackups,
    isError,
    refetch,
  } = useGetBackupsQuery(
    { accountId, clusterId: defaultCluster?.id },
    { pollingInterval: isFocused && isOnline ? 15_000 : 0 },
  );

  const [deleteBackup, { isLoading: deletingBackup }] = useDeleteClusterBackupMutation();
  const [restoreBackup, { isLoading: restoringBackup }] = useRestoreClusterBackupMutation();

  useEffect(() => setBackupsList(backups), [backups]);

  const onCreateBackup = useCallback((newBackup: Backup) => {
    setBackupsList((currentList) => [newBackup, ...(currentList ? currentList : [])]);
  }, []);

  const handleDeleteBackup = useCallback(
    async (backupName: string) => {
      if (!accountId || deletingBackup) {
        return;
      }

      const result = await deleteBackup({ accountId, backupName });

      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem deleting the backup', { variant: 'error' });
      } else {
        setBackupsList((currentList) => currentList?.filter(({ name }) => name !== backupName));
        enqueueSnackbar('Backup deleted successfully', { variant: 'success' });
      }
    },
    [accountId, deleteBackup, deletingBackup, enqueueSnackbar],
  );

  const handleRestoreBackup = useCallback(
    async (backupName: string, clusterId: string) => {
      if (!accountId || restoringBackup) {
        return;
      }

      const result = await restoreBackup({ accountId, backupName, clusterId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem restoring the backup', { variant: 'error' });
      } else {
        enqueueSnackbar('Successfully requested the backup restore', { variant: 'success' });
      }
    },
    [accountId, restoreBackup, restoringBackup, enqueueSnackbar],
  );

  const backupColumns = isAllClustersBackups
    ? BACKUPS_COLUMNS
    : BACKUPS_COLUMNS.filter(({ dataKey }) => dataKey !== 'clusterName');

  const getClusterData = useCallback(
    (backupClusterId: string): { displayName?: React.ReactNode; name?: string; clusterExists: boolean } => {
      if (!accountId || !isAllClustersBackups) {
        return {
          clusterExists: true,
          ...(!isAllClustersBackups ? { name: defaultCluster.name } : {}),
        };
      }
      const cluster = availableClusters?.find(({ id: clusterId }) => clusterId === backupClusterId);
      const clusterId = cluster?.id;

      if (clusterId) {
        return {
          displayName: (
            <Tooltip title={'See Details'} placement={'top'} arrow={true}>
              <Link
                display="block"
                component="button"
                variant="body1"
                onClick={() =>
                  navigate({
                    to: '/accounts/$accountId/clusters/$clusterId',
                    params: { accountId, clusterId },
                    hash: 'backups',
                  })
                }
                color="text.primary"
                underline="hover"
                textAlign="left"
              >
                {cluster.name}
              </Link>
            </Tooltip>
          ),
          name: cluster.name,
          clusterExists: true,
        };
      }

      return {
        displayName: (
          <Typography variant="body1" sx={(theme) => ({ color: theme.palette.error.main })}>
            Deleted cluster
          </Typography>
        ),
        clusterExists: false,
      };
    },
    [availableClusters, isAllClustersBackups, defaultCluster, accountId, navigate],
  );

  const backupRows = useMemo(() => {
    if (!backupsList) {
      return [];
    }
    const rows = Array.from(backupsList);
    return rows
      .sort((a, b) => (isBefore(new Date(a.created_at), new Date(b.created_at)) ? 1 : 0))
      .map((backup) => {
        const {
          name,
          cluster_id: backupClusterId,
          short_id: shortId,
          status,
          created_at: createdAt,
          deleted_at: deletedAt,
        } = backup;
        const { displayName, clusterExists, name: clusterName = '' } = getClusterData(backupClusterId);
        const utcCreationDateTime = format(toZonedTime(createdAt, 'UTC'), BACKUPS_DATE_FORMAT);
        return {
          name,
          creationTimestamp: `${utcCreationDateTime} (UTC)`,
          creation: shortId ? 'Scheduled' : 'Manual',
          clusterName: displayName,
          status: <SeverityPill color={getStatusColor(status)}>{status}</SeverityPill>,
          rowData: {
            name,
            clusterId: backupClusterId,
            createdAt,
            deletedAt,
            status,
            clusterExists,
            clusterName,
          },
        };
      });
  }, [backupsList, getClusterData]);

  const backupRowActions = useMemo(
    () => [
      {
        name: 'Restore',
        getHandler: (data: BackupInfo) => () => {
          const snapshotDate = format(new Date(data.createdAt), BACKUPS_DATE_FORMAT);
          showConfirmation({
            actionName: 'Restore',
            title: 'Restore Backup',
            content:
              `Are you sure you want to restore your cluster '${data.clusterName}' ` +
              `to this snapshot (${snapshotDate})?`,
            warning:
              'Be careful this will revert data as well as the configuration ' +
              `of your cluster back to ${snapshotDate}`,
            actionHandler: () => handleRestoreBackup(data.name, data.clusterId),
            severity: 'warning',
            withConfirm: false,
          });
        },
        isDisabled: ({ status, clusterExists }: BackupInfo) =>
          !clusterExists || restoringBackup || ['Running', 'Skipped', 'Failed', undefined, null].includes(status),
        icon: <HistoryRoundedIcon sx={(theme) => ({ color: theme.palette.warning.main })} />,
      },
      {
        name: 'Delete',
        getHandler: (data: BackupInfo) => () =>
          showConfirmation({
            actionName: 'Delete',
            title: 'Delete Backup',
            content: `Are you sure you want to delete the backup created on ${format(
              new Date(data.createdAt),
              BACKUPS_DATE_FORMAT,
            )}${data.clusterExists ? ` for '${data.clusterName}'` : ''}? If you delete it there is no going back.`,
            actionHandler: () => handleDeleteBackup(data.name),
            withConfirm: false,
          }),
        isDisabled: ({ status }: BackupInfo) => deletingBackup || status === 'Running',
        icon: <DeleteIcon sx={(theme) => ({ color: theme.palette.error.main })} />,
      },
    ],
    [handleDeleteBackup, handleRestoreBackup, deletingBackup, restoringBackup, showConfirmation],
  );

  const isLoading = loadingBackups;

  return (
    <Box sx={{ ...sx }}>
      <Card>
        <CardHeader
          title={
            <CardTitleWithLoadingIndicator title={'Available Backups'} isLoading={!isLoading && fetchingBackups} />
          }
          action={
            !isLoading &&
            accountId && (
              <InstantBackupButton
                defaultCluster={defaultCluster}
                accountId={accountId}
                availableClusters={availableClusters}
                disabled={loadingClusters}
                onCreateBackup={onCreateBackup}
              />
            )
          }
        />
        <Scrollbar>
          {isLoading && <Skeleton variant="rectangular" height={180} />}
          {isError && <ErrorBox retry={refetch} sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }} />}
          {!isLoading && !isError && (
            <BackupsTableContainer columns={backupColumns} rows={backupRows} actions={backupRowActions} />
          )}
        </Scrollbar>
      </Card>

      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
    </Box>
  );
}
