import { queryOptions } from '@tanstack/react-query';
import { ApiClientError, QueryData } from '../client';
import { queryClient } from '../query-client';

export type SettingsData = { maintenance: boolean; tag: string };

queryClient.setQueryDefaults<SettingsData>(['settings'], {
  async queryFn() {
    const response = await fetch('/settings.json', { headers: { 'Content-Type': 'application/json' } });
    if (response.ok) {
      return (await response.json()) as SettingsData;
    }
    throw new ApiClientError('Failed to fetch /settings.json', response.status);
  },
  retry: 0,
  refetchInterval: 10_000,
  notifyOnChangeProps: ['data'],
  placeholderData: { maintenance: false, tag: '' },
});

export const featureFlagsQuery = queryOptions<QueryData<'/config/feature_flags'>>({
  queryKey: ['/config/feature_flags'],
});

queryClient.setQueryDefaults(featureFlagsQuery.queryKey, {
  staleTime: 30_000,
});
