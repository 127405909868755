import { Box, Card, CardHeader, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { MetricsTimeFrameToggleGroup } from './MetricsTimeFrameToggleGroup';
import { NodeMetricsWithTimeSlices } from './NodeMetricsWithTimeSlices';
import { getMaxResources, TIME_FRAMES } from './utils';
import { Cluster, useGetClusterMetricsByClusterIdQuery } from '../../../services/clustersApi';
import { getNodeNumber, isClusterHybridCloud, isClusterUsageMetrics } from '../../../utils/cluster-utils';
import { captureException } from '../../../utils/error-utils';
import { ErrorBox } from '../../Common/ErrorBox';

type ClusterMetricsProps = {
  cluster: Cluster;
  accountId: string;
  timeFrameIndex: number;
  onTimeFrameChange: (event: MouseEvent<HTMLElement>, value: number) => void;
};

export const ClusterMetrics = ({ cluster, accountId, timeFrameIndex, onTimeFrameChange }: ClusterMetricsProps) => {
  const xAxisRange = useMemo(() => {
    // Define unix timestamp of now
    const now = Math.trunc(Date.now() / 1000);
    const timeFrameValue = TIME_FRAMES[timeFrameIndex].value;
    return {
      until: now,
      // `since` is defined based on the selected time frame from `now`
      since: now - timeFrameValue,
    };
  }, [timeFrameIndex]);

  const {
    data: metrics,
    isLoading,
    isFetching,
    refetch,
    isError,
    isSuccess,
  } = useGetClusterMetricsByClusterIdQuery({
    clusterId: cluster.id,
    accountId,
    queryType: 'time_series',
    since: xAxisRange.since,
    until: xAxisRange.until,
  });

  const [nodeId, setNodeId] = useState<string>();

  useEffect(() => {
    setNodeId(metrics?.nodes?.[0]?.node_id);
  }, [metrics]);

  const maxResources = useMemo(() => getMaxResources(cluster.resources), [cluster]);

  // Only expect usage metrics (provided by the 'time_series' queryType)
  if (metrics && !isClusterUsageMetrics(metrics)) {
    captureException(new Error('Invalid cluster metrics data'), {
      level: 'error',
    });
    return <ErrorBox />;
  }

  return (
    <Box>
      <Card sx={{ marginBottom: 1 }}>
        <CardHeader
          title="Metrics"
          action={
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isSuccess && nodeId ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <InputLabel id="nodeSelectLabel">Node</InputLabel>
                  <Select
                    labelId="nodeSelectLabel"
                    size="small"
                    style={{ fontSize: '13px' }}
                    id="node-select"
                    value={nodeId}
                    onChange={(event) => {
                      setNodeId(event.target.value);
                    }}
                  >
                    {metrics?.nodes?.map((node) => (
                      <MenuItem key={node.node_id} value={node.node_id} style={{ fontSize: '13px' }}>
                        #{getNodeNumber(cluster.id, node.node_id)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : (
                ''
              )}{' '}
              <MetricsTimeFrameToggleGroup
                isFetching={isFetching}
                timeFrameIndex={timeFrameIndex}
                onTimeFrameChange={onTimeFrameChange}
              />
            </Box>
          }
        />
      </Card>
      {isError || isLoading || isFetching || !nodeId ? (
        <Card>{isError ? <ErrorBox retry={refetch} /> : <Skeleton variant="rounded" height={240} />}</Card>
      ) : (
        <NodeMetricsWithTimeSlices
          isHybridCloudCluster={isClusterHybridCloud(cluster)}
          node={metrics?.nodes?.find((node) => node.node_id === nodeId)}
          key={nodeId}
          isLoading={isFetching}
          maxResources={maxResources}
          xAxisRange={xAxisRange}
          timeFrame={TIME_FRAMES[timeFrameIndex].value}
          clusterResources={cluster.resources}
        />
      )}
    </Box>
  );
};
