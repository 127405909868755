import { useAccountUser } from '../routes/_account';
import { AccountPrivilege, UserPermission } from '../utils/constants/access-control';

export function useAccessControl({
  privileges: accountPrivileges,
  permissions: userPermissions,
}: {
  privileges?: AccountPrivilege[];
  permissions?: UserPermission[];
}): { match: true } | { match: false; privileges?: AccountPrivilege[]; permissions?: UserPermission[] } {
  const {
    permissions = [],
    account: { privileges = [] },
  } = useAccountUser();

  if (!(accountPrivileges?.length || userPermissions?.length)) {
    throw new Error("useAccessControl(): 'privileges' and/or 'permissions' are required when using this hook.");
  }
  const missingPrivileges = accountPrivileges?.filter((p) => !privileges.includes(p));
  const missingPermissions = userPermissions?.filter((p) => !permissions.includes(p));
  if (missingPrivileges?.length || missingPermissions?.length) {
    return { match: false, privileges: missingPrivileges, permissions: missingPermissions };
  }

  return { match: true };
}
