import { styled, Breadcrumbs, Typography } from '@mui/material';
import { createRoute, redirect } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { useAccountId } from './_account';
import { Route as ClusterRoute } from './_cluster';
import { featureFlagsQuery } from '../api/services/core';
import { AccountDashboardAppBar } from '../components/AccountDashboard/AccountDashboardBar';
import { ClusterScalePreCheck } from '../components/Clusters/ClusterSetup/ClusterScalePreCheck';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { enqueueSnackbar } from '../hooks/use-qdrant-snackbar';
import { Link } from '../router/Link';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '../router/utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Scale cluster',
    },
  ],
  getParentRoute: () => ClusterRoute,
  path: 'scale',
  validateSearch: (search) => clusterCreateSearchSchema.and(clusterSetupSearchSchema).parse(search),
  async beforeLoad({ context: { queryClient }, params }) {
    const featureFlags = await queryClient.ensureQueryData(featureFlagsQuery);

    if (!featureFlags.cluster_creation_enabled) {
      enqueueSnackbar({ message: 'Cluster creation is currently disabled.', variant: 'warning' });
      throw redirect({ to: '/accounts/$accountId/clusters', params });
    }
  },
  component: ClusterScaleComponent,
});

const LazyClusterScale = lazy(() =>
  import(/* webpackChunkName: "cluster-scale" */ '../components/Clusters/ClusterSetup/ClusterScale').then(
    ({ ClusterScale }) => ({ default: ClusterScale }),
  ),
);

const StyledHeader = styled(AccountDashboardAppBar, { shouldForwardProp: (prop) => prop !== 'noSidebar' })`
  flex-direction: row;
  align-items: center;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)(
  ({ theme }) => `
    margin-inline: auto;
    padding: 0 ${theme.spacing(5)};
    min-width: ${theme.breakpoints.values.md}px;
    max-width: ${theme.breakpoints.values.lg}px;
    flex: 1;
  `,
);

const StyledMain = styled('main')(
  ({ theme }) => `
    margin: ${theme.spacing(5)} auto 0;
    padding: ${theme.spacing(8)} ${theme.spacing(5)} 0;
    min-width: ${theme.breakpoints.values.md}px;
    max-width: ${theme.breakpoints.values.lg}px;
    min-height: 100vh;
  `,
);

function ClusterScaleComponent() {
  const accountId = useAccountId();
  const { clusterId } = Route.useParams();

  return (
    <>
      <StyledHeader noSidebar={true}>
        <StyledBreadcrumbs separator="›" aria-label="breadcrumb">
          <Link key="1" to="/accounts/$accountId/clusters/$clusterId" params={{ accountId, clusterId }}>
            Cluster
          </Link>
          <Typography key="2" color="text.primary">
            Scale cluster
          </Typography>
        </StyledBreadcrumbs>
      </StyledHeader>
      <StyledMain>
        <ClusterScalePreCheck accountId={accountId} clusterId={clusterId}>
          <Suspense fallback={<LoadingIndicator />}>
            <LazyClusterScale accountId={accountId} clusterId={clusterId} />
          </Suspense>
        </ClusterScalePreCheck>
      </StyledMain>
    </>
  );
}
