import { LinearProgress, styled } from '@mui/material';
import { createRoute, lazyRouteComponent, redirect } from '@tanstack/react-router';
import { Route as RootRoute } from './__root';
import { enqueueSnackbar } from '../hooks/use-qdrant-snackbar';
import { authRedirectUrl } from '../utils/constants';
import { captureException } from '../utils/error-utils';

const PendingComponent = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
});

export const SSO_CONNECTIONS = ['qdrant-okta-test-org'];

export const Route = createRoute({
  getParentRoute: () => RootRoute,
  path: 'login',
  beforeLoad({
    context: { auth },
    search: { aerr: authError, sso_connection: ssoConnection, sso_login_hint: ssoLoginHint },
  }) {
    if (auth.isAuthenticated) {
      throw redirect({ to: '/overview', replace: true });
    }
    if (ssoConnection && SSO_CONNECTIONS.includes(ssoConnection)) {
      return auth
        .loginWithRedirect({
          authorizationParams: {
            audience: window.__QDRANT_CLOUD__.auth0.audience,
            scope: window.__QDRANT_CLOUD__.auth0.scope,
            redirect_uri: authRedirectUrl,
            connection: ssoConnection,
            login_hint: ssoLoginHint,
            screen_hint: 'signup',
          },
        })
        .catch((error) => {
          captureException(error);
          throw redirect({ to: '/login', replace: true });
        });
    }
    if (authError) {
      /*
       * The `authError` query parameter is set by the Auth0 SDK when an error occurs during authentication.
       * It could also happen during the silent refreshing/retrival of the access token.
       * See: https://auth0.com/docs/api/authentication?javascript#:~:text=Standard%20Error%20Responses
       */
      enqueueSnackbar(`Authentication error: ${authError}.`.replace(/\.+?\.$/, '.'), {
        variant: 'info',
        autoHideDuration: 8_000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        preventDuplicate: true,
      });
      throw redirect({ to: '/login', replace: true });
    }
  },
  pendingComponent: PendingComponent,
  component: lazyRouteComponent(() =>
    import(/* webpackChunkName: 'login' */ '../components/Login').then(({ Login }) => ({
      default: Login,
    })),
  ),
});
