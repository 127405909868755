import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { useAccountId } from './_account';
import { Route as AccountDashboardRoute } from './_account-dashboard';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';
import { Backups } from '../components/Clusters/Backups';
import { ErrorBox } from '../components/Common/ErrorBox';
import { useGetClustersByAccountIdQuery } from '../services/clustersApi';
import { Cluster } from '../utils/cluster-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Backups',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'backups',
  component: BackupsComponent,
});

const BackupsPageBody = ({
  clusters,
  refetchClusters,
  errorLoadingClusters,
  loadingClusters,
}: {
  clusters?: Cluster[];
  refetchClusters: () => void;
  errorLoadingClusters: boolean;
  loadingClusters: boolean;
}) => {
  if (errorLoadingClusters) {
    return <ErrorBox retry={errorLoadingClusters ? refetchClusters : undefined} />;
  }

  return <Backups availableClusters={clusters} loadingClusters={loadingClusters} sx={{ mt: 3 }} />;
};

function BackupsComponent() {
  const accountId = useAccountId();
  const {
    data: clusters,
    isError: errorLoadingClusters,
    isLoading: loadingClusters,
    refetch,
  } = useGetClustersByAccountIdQuery({ accountId });

  return (
    <AccountDashboardMain title="Backups">
      <Box sx={{ marginTop: 3 }}>
        <BackupsPageBody
          clusters={clusters}
          refetchClusters={refetch}
          loadingClusters={loadingClusters}
          errorLoadingClusters={errorLoadingClusters}
        />
      </Box>
    </AccountDashboardMain>
  );
}
