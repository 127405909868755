import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SingleNodeIcon from '@mui/icons-material/ViewComfy';
import { Box, Card, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import { StackedLinearMetric } from './StackedLinearMetric';
import { Metric, RAM_LABEL } from './utils';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import {
  Cluster,
  ClusterResources,
  getNodeNumber,
  isClusterHybridCloud,
  MetricType,
  NodeMetrics,
  NodesMetrics,
} from '../../../utils/cluster-utils';
import { toReadableDateTime } from '../../../utils/invites-utils';
import { ColumnType, QdrantTable, RowAction, RowType } from '../../Common/QdrantTable';
import { Scrollbar } from '../../Common/Scrollbar';
import { toClusterReservedResourcesObject } from '../ClusterSetup/helpers';
import { formatMetricAmount, transformGigabytesToBytes } from '../helpers';

type NodeSummaryInfo = {
  nodeNumber: string;
  nodeId: string;
  url: string;
  version: string;
  startedAt: string;
};

const NODES_SUMMARY_COLUMNS: ColumnType<NodeSummaryInfo>[] = [
  { title: 'Node', dataKey: 'nodeId', align: 'center' as const },
  { title: 'RAM', dataKey: 'ram', align: 'center' as const },
  { title: 'CPU', dataKey: 'cpu', align: 'center' as const },
  { title: 'Disk', dataKey: 'disk', align: 'center' as const },
  { title: 'Version', dataKey: 'version', align: 'center' as const },
  { title: 'Started at', dataKey: 'startedAt', align: 'center' as const },
];

const LABELS = {
  [Metric.CPU]: 'CPU',
  [Metric.RAM]: 'RAM',
  [Metric.RAM_CACHE]: 'RAM Cache',
  [Metric.RAM_QDRANT_RSS]: 'RAM',
  [Metric.RSS]: RAM_LABEL,
  [Metric.DISK]: 'Disk',
};

type NodeResourceMetric = {
  value: number;
  total: number;
  reserved?: number;
  cache?: number;
  formattedValue: string;
  formattedTotal: string;
  metricType: MetricType;
  label: string;
};

function getResourceMetrics(nodeMetrics: NodeMetrics, metricType: MetricType): NodeResourceMetric {
  const metricData = nodeMetrics[metricType];

  let average: number;
  let total: number;
  if (!metricData?.avg?.current || !metricData?.total?.value) {
    average = 0;
    total = 0;
  } else {
    average = parseFloat(metricData.avg.current);
    total = parseFloat(metricData.total.value);
  }
  return {
    value: average,
    total,
    formattedValue: formatMetricAmount(metricType, average),
    formattedTotal: formatMetricAmount(metricType, total),
    metricType,
    label: LABELS[metricType],
  };
}

const NodeCell = ({
  nodeResourceInfo,
  formattingOptions,
}: {
  nodeResourceInfo: NodeResourceMetric;
  formattingOptions?: { decimalPlaces: number };
}) => {
  const theme = useTheme();
  const usageKey = nodeResourceInfo.metricType === Metric.CPU ? 'usage' : 'data';
  const metrics = [
    {
      key: 'system',
      value: nodeResourceInfo.reserved ?? 0,
      color: theme.palette.warning.main,
      tooltip: 'System',
    },
    {
      key: 'cache',
      value: nodeResourceInfo.cache ?? 0,
      color: theme.palette.secondary.main,
      tooltip: 'Cache',
    },
    {
      key: usageKey,
      value: nodeResourceInfo.value,
      color: theme.palette.mode === 'dark' ? theme.palette.info.dark : theme.palette.primary.main,
      tooltip: capitalize(usageKey),
    },
  ];

  const totalUsage = (nodeResourceInfo.cache ?? 0) + nodeResourceInfo.value + (nodeResourceInfo.reserved ?? 0);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
      <Typography color="textSecondary" variant="body2" sx={{ minWidth: '190px' }}>
        {formatMetricAmount(nodeResourceInfo.metricType, totalUsage)} of {nodeResourceInfo.formattedTotal}
      </Typography>
      <Box sx={{ width: '190px' }}>
        <StackedLinearMetric
          metrics={metrics}
          format={(value) => formatMetricAmount(nodeResourceInfo.metricType, value, formattingOptions?.decimalPlaces)}
          total={nodeResourceInfo.total}
        />
      </Box>
    </Box>
  );
};

type NodeResourceMetrics = {
  [Metric.CPU]: NodeResourceMetric;
  [Metric.RAM]: NodeResourceMetric;
  [Metric.DISK]: NodeResourceMetric;
};

function getGraphDataFromNode(
  nodeMetrics: NodeMetrics,
  clusterResources: ClusterResources,
  isHybridCloudCluster: boolean,
): NodeResourceMetrics {
  const clusterReservedResources = toClusterReservedResourcesObject(clusterResources);
  const ramMetrics = getResourceMetrics(nodeMetrics, isHybridCloudCluster ? Metric.RSS : Metric.RAM_QDRANT_RSS);
  const ramCacheMetrics = getResourceMetrics(nodeMetrics, Metric.RAM_CACHE);
  const cpuMetrics = getResourceMetrics(nodeMetrics, Metric.CPU);
  const diskMetrics = getResourceMetrics(nodeMetrics, Metric.DISK);

  ramMetrics.cache = ramCacheMetrics.value;
  ramMetrics.reserved = transformGigabytesToBytes(clusterReservedResources.memory);

  // Transform reserved CPU from millicores to cores
  cpuMetrics.reserved = clusterReservedResources.cpu / 1000;

  return {
    [Metric.RAM]: ramMetrics,
    [Metric.CPU]: cpuMetrics,
    [Metric.DISK]: diskMetrics,
  };
}

export const ClusterNodesSummary = ({ cluster, nodesMetrics }: { cluster: Cluster; nodesMetrics: NodesMetrics }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isHybridCloudCluster = isClusterHybridCloud(cluster);
  const nodesRows: RowType<NodeSummaryInfo>[] = useMemo(() => {
    if (!nodesMetrics) {
      return [];
    }
    const rows = [...nodesMetrics];

    return rows.map((nodeMetrics) => {
      const nodeResourceMetrics = getGraphDataFromNode(nodeMetrics, cluster.resources, isHybridCloudCluster);
      const nodeNumber = getNodeNumber(cluster.id, nodeMetrics.node_id);
      const ready = cluster.state?.nodes?.[nodeMetrics.node_id]?.state?.Ready === 'True';
      const version = cluster.state?.nodes?.[nodeMetrics.node_id]?.version ?? '';
      const nodeUrl = cluster.state?.nodes?.[nodeMetrics.node_id]?.endpoint ?? '';
      const startedAt = cluster.state?.nodes?.[nodeMetrics.node_id]?.started_at
        ? toReadableDateTime(cluster.state.nodes[nodeMetrics.node_id]?.started_at ?? '')
        : '';
      const rowData: NodeSummaryInfo = { version, url: nodeUrl, nodeNumber, startedAt, nodeId: nodeMetrics.node_id };
      return {
        nodeId: (
          <div style={{ display: 'flex' }}>
            <SingleNodeIcon color={ready ? 'success' : 'error'} />
            <Typography variant={'h6'} sx={{ ml: 1 }}>
              #{nodeNumber}
            </Typography>
          </div>
        ),
        ram: <NodeCell nodeResourceInfo={nodeResourceMetrics.ram} />,
        cpu: <NodeCell nodeResourceInfo={nodeResourceMetrics.cpu} formattingOptions={{ decimalPlaces: 5 }} />,
        disk: <NodeCell nodeResourceInfo={nodeResourceMetrics.disk} />,
        version,
        startedAt,
        rowData,
      };
    });
  }, [nodesMetrics, cluster, isHybridCloudCluster]);

  const rowActions: RowAction<NodeSummaryInfo>[] = useMemo(
    () => [
      {
        name: 'Copy node URL',
        icon: <ContentCopyIcon />,
        getHandler:
          ({ url, nodeNumber }: NodeSummaryInfo) =>
          async () => {
            try {
              await navigator.clipboard.writeText(url);
              enqueueSnackbar(`Node ${nodeNumber} URL copied to clipboard`, {
                variant: 'success',
                autoHideDuration: 2000,
              });
            } catch {
              enqueueSnackbar('Failed to copy node URL', { variant: 'error' });
            }
          },
        isDisabled: false,
      },
      // The below action should be added/implemented once it is possible to link to the node metrics page
      // {
      //   name: 'View node metrics',
      //   getHandler: (_data) => () => {},
      //   isDisabled: (_data) => true,
      //   icon: <VisibilityOutlinedIcon color="primary" />,
      // },
    ],
    [enqueueSnackbar],
  );
  return (
    <Card>
      <Scrollbar>
        <QdrantTable<NodeSummaryInfo>
          columns={NODES_SUMMARY_COLUMNS}
          rows={nodesRows}
          ariaLabel="Nodes Summary"
          rowActions={rowActions}
        />
      </Scrollbar>
    </Card>
  );
};
