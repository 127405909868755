import EditIcon from '@mui/icons-material/Edit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import TerminalIcon from '@mui/icons-material/Terminal';
import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Tabs,
  Tab,
  CardContent,
  CircularProgress,
  Button,
  Alert,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
  IconButton,
} from '@mui/material';
import { createRoute, useLocation } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useState, useMemo, Suspense, lazy } from 'react';
import { useAccountId } from './_account';
import { Route as ClusterRoute } from './_cluster';
import { ClusterAccess } from '../components/Access/ClusterAccess';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';
import { ClusterBackupsTab } from '../components/Clusters/Backups/ClusterBackupsTab';
import { ClusterDeleteButton } from '../components/Clusters/ClusterButtons/ClusterDeleteButton';
import { ClusterRestartButton } from '../components/Clusters/ClusterButtons/ClusterRestartButton';
import { ClusterScaleAction } from '../components/Clusters/ClusterButtons/ClusterScaleAction';
import ClusterUnsuspendButton from '../components/Clusters/ClusterButtons/ClusterUnsuspendButton';
import { ClusterVersionUpdate } from '../components/Clusters/ClusterButtons/ClusterVersionUpdate';
import { ClusterDatabaseAccess } from '../components/Clusters/ClusterDatabaseAccess';
import { ClusterLogs } from '../components/Clusters/ClusterLogs';
import { ClusterNodeState } from '../components/Clusters/ClusterNodeState';
import { ClusterState } from '../components/Clusters/ClusterState';
import { ClusterConfigurationWidget } from '../components/Clusters/ClusterWidgets';
import { EnvironmentName } from '../components/Clusters/EnvironmentName';
import { ClusterMetrics } from '../components/Clusters/Metrics/ClusterMetrics';
import { ClusterOverview } from '../components/Clusters/Metrics/ClusterOverview';
import { RequestMetrics } from '../components/Clusters/Metrics/RequestMetrics';
import { getDashboardURL } from '../components/Clusters/Metrics/utils';
import { DEFAULT_TIME_FRAME_INDEX } from '../components/Clusters/Metrics/utils';
import { RenameClusterModal } from '../components/Clusters/RenameCluster';
import { CLOUD_PROVIDER_IMGS } from '../components/Clusters/constants';
import { DangerAvatar } from '../components/Common/Avatars';
import { CopyToClipboardText } from '../components/Common/CopyToClipboardText';
import { ErrorBox } from '../components/Common/ErrorBox';
import { useOnlineConnection } from '../components/Common/NetworkProvider';
import { SeverityPill } from '../components/Common/SeverityPill';
import { useWindowFocus } from '../hooks/use-window-focus';
import { HybridCloudIcon } from '../icons/hybrid-cloud-icon';
import { useGetClusterByIdQuery, useLazyGetHybridCloudEnvQuery } from '../services/clustersApi';
import { imageFilters } from '../styles';
import { Cluster, getClusterEndpoint, isClusterFreeTier, isClusterHybridCloud } from '../utils/cluster-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cluster',
    },
  ],
  getParentRoute: () => ClusterRoute,
  path: '/',
  component: ClusterDetailComponent,
});

const ClusterConfigurationLazy = lazy(() =>
  import(/* webpackChunkName: 'cluster-configuration' */ '../components/Clusters/ClusterConfiguration').then(
    ({ ClusterConfiguration }) => ({ default: ClusterConfiguration }),
  ),
);
const KubernetesConfigurationTabLazy = lazy(() =>
  import(
    /* webpackChunkName: 'kubernetes-cluster-configuration' */ '../components/Clusters/KubernetesConfigurationTab'
  ).then(({ KubernetesConfigurationTab }) => ({
    default: KubernetesConfigurationTab,
  })),
);

const TABS = [
  { label: 'Overview', value: 'overview' },
  { label: 'Access Database', value: 'usage' },
  { label: 'Metrics', value: 'metrics' },
  { label: 'Requests', value: 'requests' },
  { label: 'Backups', value: 'backups' },
  { label: 'API keys', value: 'apiKeys' },
  { label: 'Configuration', value: 'config' },
  { label: 'Kubernetes Configuration', value: 'kubernetesConfig' },
  { label: 'Logs', value: 'logs' },
  { label: 'Danger Zone', value: 'danger' },
];

function CloudProviderImage({ cluster }: { cluster: Cluster }) {
  const theme = useTheme();
  const providerImgMetadata = CLOUD_PROVIDER_IMGS[cluster.cloud_provider];

  return (
    <img
      alt={cluster.cloud_provider}
      width="50"
      src={providerImgMetadata.src}
      style={imageFilters(theme, false, providerImgMetadata.themed)}
    />
  );
}

function ClusterDetailComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeFrameIndex, setTimeFrameIndex] = useState(DEFAULT_TIME_FRAME_INDEX);
  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();
  const { clusterId } = Route.useParams();
  const accountId = useAccountId();
  const {
    data: cluster,
    isLoading,
    isError: isClusterError,
    refetch,
  } = useGetClusterByIdQuery(
    { clusterId, accountId },
    { refetchOnFocus: true, pollingInterval: isFocused && isOnline ? 10_000 : 0, refetchOnMountOrArgChange: true },
  );

  const isHybridCloudCluster = isClusterHybridCloud(cluster);

  const tabs = useMemo(() => {
    let filteredTabs = Array.from(TABS);

    // If cluster hasn't been loaded yet, do not display nor make the tabs accessible.
    if (!cluster) {
      filteredTabs = filteredTabs.filter(({ value }) => value !== 'config');
    }
    if (!cluster || !isHybridCloudCluster) {
      filteredTabs = filteredTabs.filter(({ value }) => value !== 'kubernetesConfig');
    }
    if (!cluster || isHybridCloudCluster) {
      filteredTabs = filteredTabs.filter(({ value }) => value !== 'requests');
      filteredTabs = filteredTabs.filter(({ value }) => value !== 'apiKeys');
    }
    return filteredTabs;
  }, [isHybridCloudCluster, cluster]);

  const [
    getHybridCloudEnv,
    { data: hybridCloudEnv, isError: errorLoadingHybridCloudEnv, isLoading: isLoadingHybridCloudEnv },
  ] = useLazyGetHybridCloudEnvQuery();

  useEffect(() => {
    if (isHybridCloudCluster && cluster) {
      void getHybridCloudEnv({ account_id: accountId, hybrid_cloud_env_id: cluster.private_region_id! });
    }
  }, [accountId, isHybridCloudCluster, getHybridCloudEnv, cluster]);

  let cloudProviderImg = <CircularProgress size={10} />;
  if (!isLoading && cluster) {
    cloudProviderImg = <CloudProviderImage cluster={cluster} />;
  }

  const handleTimeFrameChange = useCallback((_event: unknown, newTimeFrameIndex?: number) => {
    if (newTimeFrameIndex != null) {
      setTimeFrameIndex(newTimeFrameIndex);
    }
  }, []);

  const dashboardURL = getDashboardURL(cluster);
  const isSuspended =
    !isLoading &&
    (cluster?.state?.current === 'suspended' || (cluster?.state === null && cluster?.configuration?.num_nodes === 0));

  const actionButtons =
    !isLoading && cluster
      ? [
          dashboardURL ? (
            <Button
              onClick={() => window.open(dashboardURL, '_blank')}
              type="button"
              variant="outlined"
              endIcon={<TerminalIcon fontSize="small" />}
              sx={{ m: 1 }}
            >
              Open Dashboard
            </Button>
          ) : null,
          !isSuspended ? <ClusterRestartButton cluster={cluster} accountId={accountId} /> : null,
          isSuspended ? <ClusterUnsuspendButton cluster={cluster} accountId={accountId} /> : null,
          <ClusterScaleAction cluster={cluster} accountId={accountId} key={4} trackingLocation="Cluster Detail">
            <Button
              sx={{ minWidth: 136, m: 1 }}
              variant="outlined"
              color="success"
              endIcon={<ImportExportIcon fontSize="small" />}
            >
              Scale
            </Button>
          </ClusterScaleAction>,
        ]
      : [];

  // Tabs
  // Check if the tab was defined on the hash, otherwise open the default
  const [currentTab, setCurrentTab] = useState(
    () => tabs.find(({ value }) => `#${value}` === location.hash)?.value ?? tabs[0].value,
  );

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const handleTabsChange = useCallback(
    async (_event: unknown, value: string) => {
      setCurrentTab(value);
      await navigate({ hash: value });
    },
    [navigate],
  );

  const clusterEndpoint = getClusterEndpoint(cluster);

  const hasApiKeyConfigured =
    Boolean(cluster?.configuration?.qdrant_configuration?.service?.api_key?.secretKeyRef.key) &&
    Boolean(cluster?.configuration?.qdrant_configuration?.service?.api_key?.secretKeyRef.name);

  return (
    <AccountDashboardMain title="Cluster Details" buttons={actionButtons.filter(Boolean)}>
      {isClusterError ? (
        <ErrorBox retry={refetch} />
      ) : (
        !isLoading &&
        cluster && (
          <>
            <Card>
              <CardHeader
                title={
                  <Box>
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      Cluster Name: {cluster.name}
                      {isClusterFreeTier(cluster) && (
                        <SeverityPill color="primary" small={true}>
                          Free tier
                        </SeverityPill>
                      )}
                      <IconButton aria-label="edit" onClick={() => setIsRenameModalOpen(true)} sx={{ ml: 1 }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    <Box sx={{ mt: 3, maxWidth: '380px' }}>
                      <CopyToClipboardText text={cluster.id} label="ID" />
                    </Box>
                  </Box>
                }
                action={
                  // Exclude legacy(cloud_region: us-east) clusters from the version update
                  !isSuspended && !/^us-east$/.test(cluster.cloud_region) ? (
                    <ClusterVersionUpdate cluster={cluster} label={true} accountId={accountId} />
                  ) : null
                }
              />
              <Divider />
              <Box
                px={2}
                py={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box px={2}>
                  <ClusterConfigurationWidget cluster={cluster} />
                </Box>
                <Box px={2}>
                  <Typography variant="body2">
                    {isHybridCloudCluster ? <HybridCloudIcon /> : cloudProviderImg}
                  </Typography>
                  <EnvironmentName
                    isError={errorLoadingHybridCloudEnv}
                    isLoadingHybridCloudEnv={isLoadingHybridCloudEnv}
                    isHybridCloudCluster={isHybridCloudCluster}
                    hybridCloudEnv={hybridCloudEnv}
                    regionName={cluster.cloud_region}
                  />
                </Box>
                <Box flexGrow={1} pl={8}>
                  <Box display="flex">
                    <Box pr={2}>
                      <ClusterState cluster={cluster} />
                    </Box>
                    <Box>
                      <ClusterNodeState cluster={cluster} />
                    </Box>
                  </Box>
                  {clusterEndpoint && (
                    <Box pt={2}>
                      <CopyToClipboardText text={clusterEndpoint} label="Endpoint" />
                    </Box>
                  )}
                </Box>
              </Box>
            </Card>
            {isClusterHybridCloud(cluster) &&
            !getClusterEndpoint(cluster).endsWith('.svc:6333') &&
            !hasApiKeyConfigured ? (
              <Alert severity="warning" sx={{ mt: 2 }}>
                Your Qdrant database cluster seems to be exposed without an API key. You should configure an API key in
                the Configuration section to ensure security.
              </Alert>
            ) : null}
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
              sx={{ mt: 3 }}
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider sx={{ mb: 3 }} />
            <Box mb={5}>
              {currentTab === 'overview' && <ClusterOverview cluster={cluster} accountId={accountId} />}
              {currentTab === 'usage' && <ClusterDatabaseAccess cluster={cluster} dashboardURL={dashboardURL} />}
              {currentTab === 'metrics' && cluster.resources && (
                <ClusterMetrics
                  cluster={cluster}
                  accountId={accountId}
                  timeFrameIndex={timeFrameIndex}
                  onTimeFrameChange={handleTimeFrameChange}
                />
              )}
              {currentTab === 'requests' && (
                <RequestMetrics
                  clusterId={cluster.id}
                  accountId={accountId}
                  timeFrameIndex={timeFrameIndex}
                  onTimeFrameChange={handleTimeFrameChange}
                />
              )}
              {currentTab === 'backups' && (
                <ClusterBackupsTab cluster={cluster} accountId={accountId} privateRegion={hybridCloudEnv} />
              )}
              {currentTab === 'apiKeys' && <ClusterAccess clusterId={cluster.id} />}
              {currentTab === 'config' && (
                <Suspense fallback={<CircularProgress size={20} />}>
                  <ClusterConfigurationLazy cluster={cluster} />
                </Suspense>
              )}
              {currentTab === 'kubernetesConfig' && (
                <Suspense fallback={<CircularProgress size={20} />}>
                  <KubernetesConfigurationTabLazy cluster={cluster} />
                </Suspense>
              )}
              {currentTab === 'logs' && (
                <ClusterLogs
                  isOnPremiseCluster={isHybridCloudCluster}
                  clusterId={cluster.id}
                  accountId={accountId}
                  privateRegion={hybridCloudEnv}
                />
              )}
              {currentTab === 'danger' && (
                <Box mb={3}>
                  <Card aria-labelledby="danger-zone-title">
                    <CardHeader
                      avatar={<DangerAvatar />}
                      title={
                        <Typography variant="h6" id="danger-zone-title">
                          Danger zone
                        </Typography>
                      }
                      subheader="Irreversible and destructive actions"
                    />
                    <Divider />
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Delete cluster: {cluster.name}
                      </Typography>
                      <Typography>Once you delete a cluster, there is no going back.</Typography>
                      <ClusterDeleteButton cluster={cluster} accountId={accountId} sx={{ mt: 3 }} />
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Box>
            <RenameClusterModal
              open={isRenameModalOpen}
              onClose={() => setIsRenameModalOpen(false)}
              currentName={cluster.name}
              accountId={accountId}
              clusterId={cluster.id}
            />
          </>
        )
      )}
    </AccountDashboardMain>
  );
}
