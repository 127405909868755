/* eslint-disable max-len */
import { Skeleton } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { memo, useCallback, useEffect } from 'react';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { parseFetchError } from '../../services/helpers';
import { useGetStripeSessionByIdQuery, useLazyGetPaymentInformationQuery } from '../../services/paymentApi';
import { ComponentSchema } from '../../utils/api-schema-utils';
import { captureException } from '../../utils/error-utils';

type StripeSetupIntent = ComponentSchema<'StripeSetupIntent'>;

/**
 * The field `setup_intent` can be expanded into an object with the `expand` request parameter.
 */
function isStripeSetupIntentObject(setupIntent: string | StripeSetupIntent): setupIntent is StripeSetupIntent {
  return typeof setupIntent !== 'string' && typeof setupIntent.status === 'string';
}

export const PaymentConnectedStripeCallback = memo(function PaymentConnectedStripeCallback({
  sessionId,
  accountId,
}: {
  sessionId: string;
  accountId: string;
}) {
  /**
   * getPaymentInformation: Anytime we get a successful session with succeeded payment intent we store the payment method:
   * @see https://github.com/qdrant/qdrant-cloud-cluster-api/blob/af86f601965d190a20944286390ff742fd7d2cbf/cluster_api/booking/payment/stripe_/service_fn.py#L173
   */
  const [getPaymentInformation, paymentInformationResult] = useLazyGetPaymentInformationQuery();
  const { data, error } = useGetStripeSessionByIdQuery({ session_id: sessionId, account_id: accountId });
  const { enqueueSnackbar } = useSnackbar();
  // Redirection logic:
  const navigate = useNavigate();
  // Replace the URL to avoid re-triggering the payment setup
  const redirect = useCallback(
    // Cleanup search param from the URL to apply in redirect
    () => navigate({ search: (prev) => ({ ...prev, session_id: undefined }), replace: true }),
    [navigate],
  );
  const handleError = useCallback(
    async (data?: unknown) => {
      await redirect();
      enqueueSnackbar(
        'Unfortunately your payment setup failed. ' +
          'Please try again in a few moments. ' +
          'If the problem persist, contact support.',
        {
          variant: 'error',
        },
      );
      if (data) {
        captureException(new Error(`Stripe setup failed: ${JSON.stringify(data)}`), {
          level: 'error',
        });
      }
    },
    [redirect, enqueueSnackbar],
  );

  useEffect(() => {
    if (error) {
      void handleError();
      captureException(parseFetchError(error), { level: 'error' });
      return;
    }
    if (data) {
      if (!isStripeSetupIntentObject(data.setup_intent)) {
        void handleError(data);
        return;
      }
      if (data.setup_intent.status === 'succeeded') {
        void getPaymentInformation({ accountId });
      } else {
        void handleError(data);
      }
    }
  }, [accountId, data, error, handleError, getPaymentInformation]);

  useEffect(() => {
    if (paymentInformationResult.isSuccess) {
      void redirect();
      return;
    }
    if (paymentInformationResult.isError) {
      void handleError();
      captureException(parseFetchError(paymentInformationResult.error), { level: 'error' });
    }
  }, [
    enqueueSnackbar,
    handleError,
    paymentInformationResult.error,
    paymentInformationResult.isError,
    paymentInformationResult.isSuccess,
    redirect,
  ]);

  return <Skeleton variant="rounded" height={52} />;
});
