import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from './_account-dashboard';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';
import { PaymentSection } from '../components/Billing/PaymentSection';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Billing',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'billing',
  component: BillingComponent,
});

function BillingComponent() {
  return (
    <AccountDashboardMain title="Billing Details">
      <PaymentSection />
    </AccountDashboardMain>
  );
}
