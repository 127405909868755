import { Box, Card, Skeleton } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import Chart from 'react-apexcharts';
import {
  CHART_CARD_HEIGHT,
  CHART_COLORS,
  CHART_HEIGHT,
  ChartMetricData,
  METRICS_DETAILS,
  Metric,
  getChartOptions,
} from './utils';
import { ClusterResources, MetricType, NodesUsageMetrics } from '../../../utils/cluster-utils';
import { toClusterReservedResourcesObject } from '../ClusterSetup/helpers';
import { transformGigabytesToBytes } from '../helpers';

const CHART_BOX_STYLE = { padding: 1 };

type MetricValues = [number, number][];

/**
 * For the stacked RAM chart, we need to break down the RAM metrics into three categories:
 * - System: the reserved RAM for the system
 * - Cache: the cached RAM
 * - Data: the RAM used by the DB
 *
 * System reserved RAM is provided as part of the cluster resources information, and not part of the metrics,
 * so we need to create a new series for it, which repeats the same value for each timestamp.
 */
const getBrokenDownRAMMetrics = ({
  ramWithoutCache,
  ramCache,
  systemReservedRAM,
}: {
  ramWithoutCache: MetricValues;
  ramCache: MetricValues;
  systemReservedRAM: number;
}) => {
  const ramMetrics = ramWithoutCache.reduce<{ data: MetricValues; cache: MetricValues; system: MetricValues }>(
    (metrics, metricValue, index) => {
      const [timestamp, valueWithoutCache] = metricValue;
      const [, cacheValue] = ramCache[index] ?? []; // Use nullish coalescing to handle missing values or empty array
      const systemUsageInBytes = transformGigabytesToBytes(systemReservedRAM);

      return {
        system: [...metrics.system, [timestamp, systemUsageInBytes]],
        cache: [...metrics.cache, [timestamp, cacheValue]],
        data: [...metrics.data, [timestamp, valueWithoutCache]],
      };
    },
    { data: [], cache: [], system: [] },
  );
  return ramMetrics;
};

/**
 * For the stacked Cpu chart, we need to break down the Cpu metrics into two categories:
 * - System: the reserved Cpy for the system
 * - Usage: the Cpu used by the DB
 *
 * System reserved Cpu is provided as part of the cluster resources information, and not part of the metrics,
 * so we need to create a new series for it, which repeats the same value for each timestamp.
 */
const getBrokenDownCpuMetrics = ({
  metrics,
  systemReservedCpu,
}: {
  metrics: MetricValues;
  systemReservedCpu: number;
}) => {
  const detailedMetrics = metrics.reduce<{ usage: MetricValues; system: MetricValues }>(
    (metrics, metricValue) => {
      const [timestamp] = metricValue;
      const systemReservedCpuInMillis = systemReservedCpu / 1000;
      return {
        system: [...metrics.system, [timestamp, systemReservedCpuInMillis]],
        usage: [...metrics.usage, metricValue],
      };
    },
    { usage: [], system: [] },
  );
  return detailedMetrics;
};

/**
 * Transforms the metrics data into a format that can be used by the chart component (from string to float).
 */
const getMappedMetrics = (metric: MetricType, nodeMetrics?: NonNullable<NodesUsageMetrics>[number]): MetricValues =>
  (nodeMetrics?.[metric] ?? []).map(([timestamp, value]) => [timestamp, parseFloat(value)]);

type NodeMetricsWithTimeSlicesProps = {
  isHybridCloudCluster: boolean;
  node?: NonNullable<NodesUsageMetrics>[number];
  maxResources: {
    [Metric.CPU]: number;
    [Metric.RAM]: number;
    [Metric.DISK]: number;
  };
  timeFrame: number;
  xAxisRange: {
    since: number;
    until: number;
  };
  isLoading: boolean;
  clusterResources: ClusterResources;
};

export const NodeMetricsWithTimeSlices = ({
  isHybridCloudCluster,
  node,
  maxResources,
  timeFrame,
  xAxisRange,
  isLoading,
  clusterResources,
}: NodeMetricsWithTimeSlicesProps) => {
  const theme = useTheme();

  const ramSeries: ApexAxisChartSeries = useMemo(() => {
    const ramCache = getMappedMetrics(Metric.RAM_CACHE, node);
    const ramWithoutCache = getMappedMetrics(isHybridCloudCluster ? Metric.RSS : Metric.RAM_QDRANT_RSS, node);
    const systemReservedRAM = clusterResources?.ram?.reserved ?? 0;
    const ramMetrics = getBrokenDownRAMMetrics({
      ramWithoutCache,
      ramCache,
      systemReservedRAM,
    });
    return [
      {
        name: 'System',
        data: ramMetrics.system,
      },
      {
        name: 'Cache',
        data: ramMetrics.cache,
      },
      {
        name: 'Data',
        data: ramMetrics.data,
      },
    ];
  }, [node, clusterResources, isHybridCloudCluster]);

  const cpuSeries: ApexAxisChartSeries = useMemo(() => {
    const systemReservedCpu = toClusterReservedResourcesObject(clusterResources).cpu;
    const cpuMetrics = getBrokenDownCpuMetrics({ metrics: getMappedMetrics(Metric.CPU, node), systemReservedCpu });
    return [
      {
        name: 'System',
        data: cpuMetrics.system,
      },
      {
        name: 'Usage',
        data: cpuMetrics.usage,
      },
    ];
  }, [node, clusterResources]);

  const diskSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: METRICS_DETAILS[Metric.DISK].label,
        data: getMappedMetrics(Metric.DISK, node),
      },
    ],
    [node],
  );

  const getChartOptsShort = useCallback(
    (metrics: ChartMetricData[], customTooltip?: { dataSeries: ApexAxisChartSeries }) =>
      getChartOptions({
        metrics,
        timeFrame,
        xAxisRange,
        theme,
        stacked: true,
        customTooltip,
      }),
    [timeFrame, theme, xAxisRange],
  );

  const ramChartOpts = useMemo(
    () =>
      getChartOptsShort(
        [
          // We use the RAM's max for both RAM (ram with cache) and RSS (without cache)
          // System
          { name: Metric.RAM, color: CHART_COLORS.YELLOW, max: maxResources[Metric.RAM], hideLeftBar: true },
          // Cache
          { name: Metric.RAM, color: CHART_COLORS.RED, max: maxResources[Metric.RAM] },
          // Data
          { name: Metric.RAM, color: CHART_COLORS.BLUE, max: maxResources[Metric.RAM] },
        ],
        { dataSeries: ramSeries },
      ),
    [getChartOptsShort, maxResources, ramSeries],
  );

  const cpuChartOpts = useMemo(() => {
    const max = maxResources[Metric.CPU];
    return getChartOptsShort(
      [
        { name: Metric.CPU, color: CHART_COLORS.YELLOW, max },
        { name: Metric.CPU, color: CHART_COLORS.BLUE, max },
      ],
      { dataSeries: cpuSeries },
    );
  }, [getChartOptsShort, maxResources, cpuSeries]);

  const diskChartOpts = useMemo(() => {
    const max = maxResources[Metric.DISK];
    return getChartOptsShort([
      { name: Metric.DISK, color: CHART_COLORS.BLUE, max },
      { name: Metric.DISK, color: CHART_COLORS.BLUE, max },
    ]);
  }, [getChartOptsShort, maxResources]);

  const charts = [
    { options: ramChartOpts, series: ramSeries },
    { options: cpuChartOpts, series: cpuSeries },
    { options: diskChartOpts, series: diskSeries },
  ];

  return (
    <Box key={node?.node_id}>
      {charts.map(({ options, series }, key) => (
        <Card sx={{ marginBottom: 2, minHeight: CHART_CARD_HEIGHT }} key={key}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={CHART_CARD_HEIGHT} />
          ) : (
            <Box sx={CHART_BOX_STYLE} overflow="hidden">
              <Chart options={options} series={series} height={CHART_HEIGHT} type="area" />
            </Box>
          )}
        </Card>
      ))}
    </Box>
  );
};
