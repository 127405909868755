import { Box, Typography } from '@mui/material';
import { useNavigate, createRoute } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';
import { useAccountId } from './_account';
import { Route as AccountDashboardRoute } from './_account-dashboard';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';
import { NewsItemList } from '../components/News/NewsItemList';
import { HybridCloudBanner } from '../components/Overview/HybridCloudBanner';
import { OverviewBanner } from '../components/Overview/OverviewBanner';
import { OverviewClustersWidget } from '../components/Overview/OverviewClustersWidget';
// eslint-disable-next-line max-len
import { OverviewWelcomeStepper as OverviewWelcomeStepperHorizontal } from '../components/Overview/WelcomeStepper/Horizontal/OverviewWelcomeStepper';
import { WelcomeStepperProvider } from '../components/Overview/WelcomeStepper/Horizontal/WelcomeStepperProvider';
import { OverviewWelcomeStepper } from '../components/Overview/WelcomeStepper/OverviewWelcomeStepper';
import { PendingRequests } from '../components/Profile/PendingRequests';
import { Settings } from '../contexts/settings-context';
import { useSettings } from '../hooks/use-settings';
import { clusterApi, useGetClustersByAccountIdQuery } from '../services/clustersApi';
import { ApiKeys, iamApi, useGetApiKeysByAccountIdQuery } from '../services/iamApi';
import { Cluster, isClusterHybridCloud } from '../utils/cluster-utils';

/*
 * This is a temporary flag to switch between the horizontal and vertical design of the welcome stepper.
 * This will be removed and used as a feature flag in the future.
 */
const isHorizontalDesign = true;

export const Route = createRoute({
  meta: () => [
    {
      title: 'Overview',
    },
  ],
  beforeLoad: ({ context, params: { accountId } }) => {
    context.store.dispatch(
      clusterApi.util.prefetch(
        'getClustersByAccountId',
        { accountId },
        {
          force: true,
        },
      ),
    );
    context.store.dispatch(
      iamApi.util.prefetch(
        'getApiKeysByAccountId',
        { account_id: accountId },
        {
          force: true,
        },
      ),
    );
  },
  getParentRoute: () => AccountDashboardRoute,
  path: 'overview',
  component: AccountOverviewComponent,
});

function AccountOverviewComponent() {
  const accountId = useAccountId();
  const navigate = useNavigate();
  const { settings, saveSettings } = useSettings();

  const { data: clusters = [] } = useGetClustersByAccountIdQuery({ accountId });
  const { data: apiKeys = [] } = useGetApiKeysByAccountIdQuery({ account_id: accountId });

  const managedCloudClusters = useMemo(() => clusters.filter((cluster) => !isClusterHybridCloud(cluster)), [clusters]);

  const handleDismissBanner = (id: keyof Settings['banners']) => {
    saveSettings({
      banners: { ...settings.banners, [id]: false },
    });
  };

  useEffect(() => {
    if (!settings.isWelcomeStepperInProgress) {
      if (managedCloudClusters?.length === 0 || apiKeys?.length === 0) {
        saveSettings({
          ...settings,
          isWelcomeStepperInProgress: true,
        });
      }
    }
  }, [managedCloudClusters, apiKeys, saveSettings, settings]);

  const getActiveStep = (clusters: Cluster[], apiKeys: ApiKeys) => {
    if (clusters.length === 0) {
      return 0;
    }
    if (!apiKeys?.some((apiKey) => apiKey.cluster_id_list?.includes(clusters[0].id))) {
      return 1;
    }
    return 2;
  };

  const handleStepperComplete = () => {
    saveSettings({
      isWelcomeStepperInProgress: false,
    });
    void navigate({ to: '/accounts/$accountId/clusters', params: { accountId } });
  };

  const showWelcomeBanner = useMemo(
    () => settings.banners.welcome && !settings.isWelcomeStepperInProgress,
    [settings.banners.welcome, settings.isWelcomeStepperInProgress],
  );

  const renderHybridCloudBanner = useMemo(
    () => settings.banners.hybridCloudLaunch && !settings.isWelcomeStepperInProgress,
    [settings.banners.hybridCloudLaunch, settings.isWelcomeStepperInProgress],
  );

  return (
    <AccountDashboardMain>
      <Box mb={4}>
        <PendingRequests onlyShowInvites={true} />
        {showWelcomeBanner && <OverviewBanner onDismiss={() => handleDismissBanner('welcome')} sx={{ mb: 2 }} />}
        {renderHybridCloudBanner && (
          <HybridCloudBanner onDismiss={() => handleDismissBanner('hybridCloudLaunch')} sx={{ mb: 3 }} />
        )}
        {settings.isWelcomeStepperInProgress && (
          <>
            {isHorizontalDesign ? (
              <WelcomeStepperProvider>
                <OverviewWelcomeStepperHorizontal
                  apiKeys={apiKeys}
                  activeStep={getActiveStep(managedCloudClusters, apiKeys)}
                  onComplete={handleStepperComplete}
                />
              </WelcomeStepperProvider>
            ) : (
              <OverviewWelcomeStepper
                clusters={managedCloudClusters}
                apiKeys={apiKeys}
                accountId={accountId}
                activeStep={getActiveStep(managedCloudClusters, apiKeys)}
                onComplete={handleStepperComplete}
                mb={5}
              />
            )}
          </>
        )}
        <OverviewClustersWidget />
      </Box>
      {!settings.isWelcomeStepperInProgress && (
        <Box>
          <Typography variant="h5" component="h2" mb={2}>
            News and Updates
          </Typography>
          <NewsItemList />
        </Box>
      )}
    </AccountDashboardMain>
  );
}
