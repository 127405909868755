import { Cached } from '@mui/icons-material';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';
import { ConfirmationDialogDangerAction } from './ConfirmationDialogDangerAction';

type ButtonDangerActionProps = {
  manualConfirmation: boolean;
  isLoading?: boolean;
  actionName: string;
  loadingStateName?: string;
  handleDangerAction: () => Promise<void>;
  buttonIcon?: JSX.Element;
  targetName: string;
  dialogTitle?: string;
  dialogContent?: string;
  dialogWarning?: string;
  buttonColor?: 'error' | 'inherit' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  disabled?: boolean;
  sx?: SxProps;
};

/**
 * ButtonDangerAction is a button that opens a confirmation dialog before executing a dangerous action.
 * @param manualConfirmation - if true, the dialog will not ask for confirmation by typing the name of the target
 * @param isLoading - if true, the button will be disabled and show a loading indicator
 * @param actionName - name of the action to be executed,
 *  shown on the button and in the dialog title and info if alternative title and info are not provided
 * @param loadingStateName - name of the action to be shown when the action is loading, by default "Processing"
 * @param handleDangerAction - function to be executed when the action is confirmed
 * @param buttonIcon - icon to be displayed on the button
 * @param targetName - name of the target of the action, for example name of the cluster to be deleted
 * @param dialogTitle - title of the dialog
 * @param dialogContent - content of the dialog, describing the action or giving more information about it
 * @param dialogWarning - highlighted text of the dialog, by default asks to type the name of the target to confirm
 * @param other - other props to be passed to the MUI button component
 * @constructor
 * @component
 * @example <caption>Simple usage</caption>
 * <ButtonDangerAction
 *    actionName="Delete"
 *    loadingStateName="Deleting"
 *    handleDangerAction={handleDelete}
 *    buttonIcon={<Delete fontSize={"small"}/>}
 *    targetName="cluster"
 *    dialogTitle="Deletion of your cluster"
 *    dialogContent="Are you sure you want to delete this cluster, this can't be reverted and will take
 *    effect immediately"
 *    dialogWarning="This action cannot be undone"
 *    />
 */
export function ButtonDangerAction({
  manualConfirmation,
  isLoading,
  actionName,
  loadingStateName = 'Processing',
  handleDangerAction,
  buttonIcon,
  targetName,
  dialogTitle,
  dialogContent,
  dialogWarning,
  buttonColor = 'error',
  disabled,
  sx,
}: ButtonDangerActionProps) {
  // default values
  buttonIcon = buttonIcon ? buttonIcon : <Cached fontSize={'small'} />;
  dialogTitle = dialogTitle ? dialogTitle : `${actionName} ${targetName}`;
  dialogContent = dialogContent
    ? dialogContent
    : `Are you sure you want to ${actionName} this ${targetName}, this can't be reverted and will take some time.`;
  dialogWarning = dialogWarning ? dialogWarning : `Please type the name of the ${targetName} to confirm.`;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (isLoading) {
      return null;
    }
    setOpen(true);
  };

  // TODO: extract button with loading state to the separate component,
  // to be able to reuse it without confirmation dialog
  return (
    <>
      <Button
        onClick={handleClickOpen}
        sx={{ ...sx, minWidth: 136, m: 1 }}
        type="submit"
        variant="outlined"
        color={buttonColor}
        disabled={disabled || isLoading}
        endIcon={isLoading ? <CircularProgress size={20} color="error" /> : buttonIcon}
      >
        {isLoading ? loadingStateName : actionName}
      </Button>

      {manualConfirmation ? (
        <ConfirmationDialogDangerAction
          open={open}
          onClose={() => setOpen(false)}
          actionTarget={targetName}
          title={dialogTitle}
          content={dialogContent}
          warning={dialogWarning}
          actionName={actionName}
          actionHandler={handleDangerAction}
        />
      ) : (
        <ConfirmationDialog
          open={open}
          onClose={() => setOpen(false)}
          title={dialogTitle}
          content={dialogContent}
          warning={dialogWarning}
          actionName={actionName}
          actionHandler={handleDangerAction}
        />
      )}
    </>
  );
}
