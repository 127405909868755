import { QueryClient } from '@tanstack/react-query';
import { Middleware, ParamsOption } from 'openapi-fetch';
import { PathsWithMethod } from 'openapi-typescript-helpers';
import { FetchTypeError, client } from './client';
import { paths } from '../api-schema';
import { ONE_HOUR_MILLISECONDS } from '../utils/constants';

type Path = PathsWithMethod<paths, 'get'>;
type Params = ParamsOption<paths[Path]>['params'];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: ONE_HOUR_MILLISECONDS / 2,

      queryFn: async ({ queryKey, signal }) => {
        /**
         * This code will attempt to perform a fetch and detect errors only when the promise
         * is rejected (which can occur in two scenarios):
         *
         * - network errors: failure to connect to the server which can be caused by several reasons,
         *   such as slow network and timeout, for example.
         * - CORS errors: when a domain is not authorised to obtain resources from a different domain.
         *
         * When the promise resolves, 'data' can be returned directly.
         * API errors are wrapped into a ApiClientError automatically via middleware (see client.ts)
         */
        try {
          const { data } = await client.GET(queryKey[0] as Path, {
            signal,
            ...(queryKey[1] as Params),
          });
          return data;
        } catch (error) {
          throw new FetchTypeError(error as Error);
        }
      },
    },
  },
});

/**
 * This middleware appends 'X-Qd-Version' header to all requests,
 * so that we know which UI App version issued the request to the API
 */
const QdVersionRegex = /(?:[0-9]{1,5}\.[0-9]{1,5}\.[0-9]{1,5})/;
const versionHeaderMiddleware: Middleware = {
  async onRequest({ request }) {
    const { tag } = await queryClient.ensureQueryData<{ tag: string }>({
      queryKey: ['settings'],
    });

    if (QdVersionRegex.test(tag)) {
      request.headers.set('X-Qd-Version', tag);
    }

    return request;
  },
};

client.use(versionHeaderMiddleware);
