import { createRouteMask } from '@tanstack/react-router';
import { Route as RootRoute } from '../routes/__root';
import { Route as AccountRoute, PathlessRoute as PathlessAccountRoute } from '../routes/_account';
import { Route as AccountDashboardRoute } from '../routes/_account-dashboard';
import { Route as AuthenticatedRoute } from '../routes/_authenticated';
import { Route as ClusterRoute } from '../routes/_cluster';
import { Route as ClustersRoute } from '../routes/_clusters';
import { Route as HybridCloudEnvironmentRoute } from '../routes/_hybrid-cloud-environment';
import { Route as HybridCloudEnvironmentsRoute } from '../routes/_hybrid-cloud-environments';
import { Route as OverviewRoute } from '../routes/_overview';
import { Route as BackupsRoute } from '../routes/backups';
import { Route as BillingRoute } from '../routes/billing';
import { Route as CalculatorRoute } from '../routes/calculator';
import { Route as CloudAccessRoute } from '../routes/cloud-access';
import { Route as ClusterCreateRoute } from '../routes/cluster-create';
import { Route as ClusterDetailRoute } from '../routes/cluster-detail';
import { Route as ClusterScaleRoute } from '../routes/cluster-scale';
import { Route as ClustersListRoute } from '../routes/clusters';
import { Route as DataAccessRoute } from '../routes/data-access';
import { Route as HybridCloudRoute } from '../routes/hybrid-cloud';
import { Route as HybridCloudEnvironmentDetailRoute } from '../routes/hybrid-cloud-environment';
import { Route as HybridCloudEnvironmentEditRoute } from '../routes/hybrid-cloud-environment-edit';
import { Route as HybridCloudEnvironmentsListRoute } from '../routes/hybrid-cloud-environments';
import { Route as HybridCloudEnvironmentsCreateRoute } from '../routes/hybrid-cloud-environments-create';
import { Route as HybridCloudEnvironmentsOnboardingRoute } from '../routes/hybrid-cloud-environments-onboarding';
import { Route as IndexRoute } from '../routes/index';
import { Route as LegalRoute } from '../routes/legal';
import { Route as LoginRoute } from '../routes/login';
import { Route as LogoutRoute } from '../routes/logout';
import { Route as AccountOverviewRoute } from '../routes/overview';
import { Route as ProfileRoute, MaskingRoute as MaskingProfileRoute } from '../routes/profile';

export const routeTree = RootRoute.addChildren([
  IndexRoute,
  LoginRoute,
  LogoutRoute,
  CalculatorRoute,
  LegalRoute,
  AuthenticatedRoute.addChildren([
    MaskingProfileRoute,
    PathlessAccountRoute.addChildren([OverviewRoute]),
    AccountRoute.addChildren([
      AccountDashboardRoute.addChildren([
        AccountOverviewRoute,
        ProfileRoute,
        ClustersRoute.addChildren([
          ClustersListRoute,
          ClusterCreateRoute,
          ClusterRoute.addChildren([ClusterDetailRoute, ClusterScaleRoute]),
        ]),
        HybridCloudRoute,
        HybridCloudEnvironmentsRoute.addChildren([
          HybridCloudEnvironmentsListRoute,
          HybridCloudEnvironmentsCreateRoute,
          HybridCloudEnvironmentsOnboardingRoute,
          HybridCloudEnvironmentRoute.addChildren([HybridCloudEnvironmentDetailRoute, HybridCloudEnvironmentEditRoute]),
        ]),
        BackupsRoute,
        CloudAccessRoute,
        DataAccessRoute,
        BillingRoute,
      ]),
    ]),
  ]),
]);

export const ProfileRouteMask = createRouteMask({
  routeTree,
  from: '/accounts/$accountId/profile',
  to: '/profile',
  params: true,
});
