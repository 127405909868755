import { useCallback } from 'react';
import { PAYMENT_INFO_SECTION_ELEMENT_ID } from './use-create-marketplace-redirect';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { parseFetchError } from '../../../services/helpers';
import { useCreateStripeSessionMutation } from '../../../services/paymentApi';
import { captureException } from '../../../utils/error-utils';
import { getUserMessage } from '../../../utils/errorHandlingUtils';

export const useCreateStripeSession = ({
  accountId,
  shouldRedirect,
}: {
  accountId: string;
  shouldRedirect: boolean;
}): (() => Promise<string | undefined>) => {
  const [createStripeSession] = useCreateStripeSessionMutation();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(async () => {
    try {
      const sessionResponse = await createStripeSession({
        account_id: accountId,
        redirect_url: `${window.location.href.replace(/(#.+)/, '')}#${PAYMENT_INFO_SECTION_ELEMENT_ID}`,
      }).unwrap();
      const url = sessionResponse.url;
      if (url) {
        if (shouldRedirect) {
          window.location.assign(url);
        }
        return url;
      }
      captureException(
        new Error(
          'Redirecting to Stripe to setup payment method failed - creating a stripe session did not return a `url`.',
        ),
        {
          tags: { context: 'payment_setup', route: window.location.toString() },
        },
      );
    } catch (err) {
      enqueueSnackbar(getUserMessage(err), { variant: 'error' });
      captureException(getUserMessage(parseFetchError(err)), {
        tags: { context: 'payment_setup', route: window.location.toString() },
      });
    }
  }, [accountId, shouldRedirect, createStripeSession, enqueueSnackbar]);
};
