import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import { useConfirmationAction } from '../../hooks/use-confirmation-action';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { useAccountUser } from '../../routes/_account';
import { Cluster } from '../../services/clustersApi';
import { isFetchMutationError } from '../../services/helpers';
import { ApiKey, useDeleteApiKeyByIdMutation } from '../../services/iamApi';
import { getUserMessage } from '../../utils/errorHandlingUtils';
import { CloudLink } from '../Common/CloudLink';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';
import { Scrollbar } from '../Common/Scrollbar';

const ClusterName = ({ accountId, cluster, theme }: { accountId: string; cluster?: Cluster; theme: Theme }) => {
  if (cluster) {
    return (
      <Typography>
        <CloudLink
          color={theme.palette.secondary.main}
          to="/accounts/$accountId/clusters/$clusterId"
          params={{ accountId, clusterId: cluster.id }}
        >
          {cluster.name}
        </CloudLink>
      </Typography>
    );
  }
  return <Typography color={theme.palette.secondary.main}>Deleted cluster</Typography>;
};

type Props = {
  apiKeys: ApiKey[];
  clusterIdToClusterMap: Map<string, Cluster>;
};

export function ApiKeyList({ apiKeys, clusterIdToClusterMap }: Props) {
  const [deleteApiKey, { isLoading: deletingApiKey }] = useDeleteApiKeyByIdMutation();
  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();
  const { account } = useAccountUser();
  const { id: accountId } = account;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteApiKey = useCallback(
    async (apiKeyId: string): Promise<void> => {
      if (deletingApiKey) {
        return;
      }
      const result = await deleteApiKey({ accountId, apiKeyId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar(getUserMessage(result.error), { variant: 'error' });
      } else {
        enqueueSnackbar('API key deleted successfuly', { variant: 'success' });
      }
    },
    [accountId, deleteApiKey, enqueueSnackbar, deletingApiKey],
  );

  const handlePromptDeleteApiKey = useCallback(
    (id: string) => {
      showConfirmation({
        actionName: 'Delete',
        title: 'Deletion of your API key',
        content:
          "Are you sure you want to delete this API key, this can't be reverted and will take effect immediately",
        actionHandler: () => handleDeleteApiKey(id),
        withConfirm: false,
      });
    },
    [showConfirmation, handleDeleteApiKey],
  );

  return (
    <>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>API Key</TableCell>
              <TableCell>Clusters</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map((apiKey) => (
              <TableRow key={apiKey.id}>
                <TableCell data-sentry-mask={true}>{apiKey.id}</TableCell>
                <TableCell data-sentry-mask={true}>{apiKey.prefix}********************</TableCell>
                <TableCell>
                  {apiKey.cluster_id_list?.map((clusterId) => (
                    <ClusterName
                      key={String(clusterId)}
                      accountId={accountId}
                      cluster={clusterIdToClusterMap.get(String(clusterId))}
                      theme={theme}
                    />
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton aria-label="Delete" color="primary" onClick={() => handlePromptDeleteApiKey(apiKey.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>

      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
    </>
  );
}
