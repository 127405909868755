import { Box, Button, Typography, styled } from '@mui/material';
import { ErrorComponentProps, useRouter } from '@tanstack/react-router';
import { AccountDashboardOutline, DASHBOARD_LAYOUT_GAP } from '.';
import { Link } from '../../router/Link';
import { Route } from '../../routes/_authenticated';
import { isErrorWithDetail } from '../../services/helpers';
import { iamApi } from '../../services/iamApi';
import { isModuleNotFoundError } from '../../utils/error-utils';

const StyledAuthenticatedErrorComponent = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};
  margin: 0 ${theme.spacing(4)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${DASHBOARD_LAYOUT_GAP});
  text-align: center;
  text-wrap: balance;
`,
);

export const AuthenticatedErrorComponent = ({ error }: ErrorComponentProps) => {
  const router = useRouter();
  const { store } = Route.useRouteContext();

  return (
    <AccountDashboardOutline>
      <StyledAuthenticatedErrorComponent role="alert">
        <Typography variant="h5" component="p">
          An error has occurred. Our team is aware of the issue and is working to resolve it.
        </Typography>
        <Typography variant="h5" component="p">
          Please try again later.
        </Typography>
        {isErrorWithDetail(error) && (
          <Typography variant="body2" component="p">
            <b>Error:</b> {error.detail}
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (isModuleNotFoundError(error)) {
                window.location.reload();
                return;
              }
              // reset IAM api state
              store.dispatch(iamApi.util.resetApiState());
              // Invalidate the route to reload the loader, which will also reset the error boundary
              return router.invalidate();
            }}
          >
            Try again
          </Button>
          or
          <Button variant="outlined" component={Link} to="/logout">
            Logout
          </Button>
        </Box>
      </StyledAuthenticatedErrorComponent>
    </AccountDashboardOutline>
  );
};
