import { Box, Skeleton } from '@mui/material';
import { ClusterNodesSummary } from './ClusterNodesSummary';
import { useWindowFocus } from '../../../hooks/use-window-focus';
import { Cluster, useGetClusterMetricsByClusterIdQuery } from '../../../services/clustersApi';
import { isClusterFreeTier, isClusterUsageMetrics } from '../../../utils/cluster-utils';
import { captureException } from '../../../utils/error-utils';
import { ErrorBox } from '../../Common/ErrorBox';
import { useOnlineConnection } from '../../Common/NetworkProvider';
import { UpgradeFreeToPaidBanner } from '../UpgradeFreeToPaidBanner';

export function ClusterOverview({ cluster, accountId }: { cluster: Cluster; accountId: string; maxWidth?: string }) {
  const clusterId = cluster.id;
  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();
  const {
    data: metrics,
    isLoading,
    isError,
    refetch,
  } = useGetClusterMetricsByClusterIdQuery(
    {
      clusterId,
      accountId,
    },
    { refetchOnFocus: true, pollingInterval: isFocused && isOnline ? 60_000 : 0, refetchOnMountOrArgChange: true },
  );
  const isClusterFree = isClusterFreeTier(cluster);
  // Only expect overview metrics (provided by the 'load_summary' queryType)
  if (metrics && isClusterUsageMetrics(metrics)) {
    captureException(new Error('Invalid cluster metrics data'), {
      level: 'error',
    });
    return <ErrorBox />;
  }

  if (!isLoading && metrics && metrics.nodes?.length === 0) {
    return (
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 3,
        }}
      >
        Usage not available
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {isClusterFree && <UpgradeFreeToPaidBanner cluster={cluster} accountId={accountId} />}
      {isLoading ? (
        <Skeleton variant="rounded" height={240} />
      ) : (
        metrics && metrics.nodes?.length && <ClusterNodesSummary cluster={cluster} nodesMetrics={metrics.nodes} />
      )}
      {!isLoading && isError && <ErrorBox retry={refetch} />}
    </Box>
  );
}
