import { Skeleton } from '@mui/material';
import { ReactNode } from 'react';
import { HybridCloudEnvironmentProvider } from './HybridCloudEnvironmentProvider';
import { useWindowFocus } from '../../../hooks/use-window-focus';
import { useAccountId } from '../../../routes/_account';
import { useGetHybridCloudEnvQuery } from '../../../services/clustersApi';
import { ErrorBox } from '../../Common/ErrorBox';
import { useOnlineConnection } from '../../Common/NetworkProvider';
import { DEFAULT_REFRESH_INTERVAL } from '../utils';

export const HybridCloudEnvironmentPreloader = ({
  hybridCloudEnvId,
  children,
}: {
  hybridCloudEnvId: string;
  children: ReactNode;
}) => {
  const accountId = useAccountId();
  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();
  const {
    data: hybridCloudEnvironment,
    isLoading,
    isError,
    refetch: refetchHybridCloudEnv,
    fulfilledTimeStamp,
  } = useGetHybridCloudEnvQuery(
    {
      account_id: accountId,
      hybrid_cloud_env_id: hybridCloudEnvId,
    },
    {
      refetchOnFocus: true,
      pollingInterval: isFocused && isOnline ? DEFAULT_REFRESH_INTERVAL : 0,
      refetchOnMountOrArgChange: true,
    },
  );

  if (isLoading) {
    return <Skeleton height={240} />;
  }

  if (isError) {
    return (
      <ErrorBox msg="There was a problem retrieving the Hybrid Cloud Environment." retry={refetchHybridCloudEnv} />
    );
  }

  if (!hybridCloudEnvironment) {
    return <>NOT FOUND</>;
  }

  return (
    <HybridCloudEnvironmentProvider
      hybridCloudEnvironment={hybridCloudEnvironment}
      fulfilledTimeStamp={fulfilledTimeStamp}
    >
      {children}
    </HybridCloudEnvironmentProvider>
  );
};
