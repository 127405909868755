import { createRoute, redirect } from '@tanstack/react-router';
import { PathlessRoute as PathlessAccountRoute, getAccountUser } from './_account';

export const Route = createRoute({
  getParentRoute: () => PathlessAccountRoute,
  path: 'overview',
  async loader({ context: { store } }) {
    const {
      account: { id: accountId },
    } = await getAccountUser(store);
    throw redirect({ to: '/accounts/$accountId/overview', params: { accountId }, replace: true });
  },
});
