import { Box, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useLocation } from '@tanstack/react-router';
import { ReactNode, useEffect, useRef } from 'react';
import { isErrorWithDetail } from '../../../services/helpers';
import { WEB_STORAGE_PREFIX } from '../../../utils/constants';
import { isModuleNotFoundError, captureException } from '../../../utils/error-utils';
import { attempt } from '../../../utils/func-utils';

export const moduleNotFoundErrorStorageKey = `${WEB_STORAGE_PREFIX}moduleNotFoundError`;

export function DefaultErrorComponent({ error, children }: { error: unknown; children?: ReactNode }) {
  const { pathname } = useLocation();
  const isReloadingRef = useRef(false);

  useEffect(() => {
    if (isReloadingRef.current) {
      return;
    }
    const moduleNotFoundError = attempt(() => sessionStorage.getItem(moduleNotFoundErrorStorageKey));

    if (moduleNotFoundError) {
      sessionStorage.removeItem(moduleNotFoundErrorStorageKey);
      // If we have a moduleNotFoundError in sessionStorage, we want to add that event as navigation breadcrumb.
      Sentry.addBreadcrumb({
        category: 'navigation',
        message: moduleNotFoundError,
        data: { from: pathname, to: pathname },
      });
    }
  }, [pathname]);

  if (isModuleNotFoundError(error)) {
    // We want to reload one time on module not found error and not enter
    // a reload loop if there is some other issue besides an old deploy.
    // That's why we store our reload attempt in sessionStorage.
    // Use error.message as key because it contains the module path that failed.
    const storageKey = `${WEB_STORAGE_PREFIX}${error.message}`;
    const didReload = attempt(
      () => sessionStorage.getItem(storageKey),
      (error) => {
        // Keep track of errors to know how many sessions DO NOT experience an automatic reload (if any).
        captureException(error, { tags: { webpack: 'ChunkLoadError' } });
        // Return true to mimick that we've already reloaded, as sessionStorage throws and we can't continue.
        return true;
      },
    );
    // If we haven't reloaded yet, reload the page
    if (!didReload) {
      sessionStorage.setItem(storageKey, '1');
      sessionStorage.setItem(moduleNotFoundErrorStorageKey, error.message);
      isReloadingRef.current = true;
      window.location.reload();

      // Return empty component while we wait for window to reload...
      return <></>;
    }
  }

  if (children) {
    return children;
  }

  return (
    <Box
      role="alert"
      sx={{ position: 'absolute', width: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <Box sx={{ width: '100%', textAlign: 'center', paddingX: '20px' }}>
        <Typography variant="h5" component="p">
          An error has occurred. Our team is aware of the issue and is working to resolve it.
          <br />
          Please try again later.
        </Typography>
        {isErrorWithDetail(error) && (
          <Typography variant="body2" component="p" sx={{ textAlign: 'center', mb: 2 }}>
            <b>Error:</b> {error.detail}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
