import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from './_account-dashboard';
import { ClusterAccess } from '../components/Access/ClusterAccess';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Data Access Control',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'data-access',
  component: DataAccessComponent,
});

function DataAccessComponent() {
  return (
    <AccountDashboardMain title="Data Access Control">
      <Box sx={{ marginTop: 3 }}>
        <ClusterAccess sx={{ mb: 3 }} />
      </Box>
    </AccountDashboardMain>
  );
}
