import CheckIcon from '@mui/icons-material/Check';
import { Grid, Skeleton, styled } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { useCreateMarketplaceRedirect } from './hooks/use-create-marketplace-redirect';
import { useCreateStripeSession } from './hooks/use-create-stripe-session';
import { PaymentInformation } from '../../services/paymentApi';
import { useGetValidPaymentMethodsQuery, useChangePaymentMethodMutation } from '../../services/paymentApi';
import { typedMemo } from '../../utils/func-utils';
import { ButtonLike } from '../Common/ButtonLike';

const StyledProviderButtonLike = styled(ButtonLike)(
  ({ theme }) => `
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    outline: 1px transparent solid;
    background-color: ${theme.palette.background.paper};
    padding-block: ${theme.spacing(1.25)};
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      outline-color: ${theme.palette.primary.light};
      border-color: ${theme.palette.primary.light};
    }
    &[aria-disabled='true'] {
      background-color: ${theme.palette.background.default};
    }
    &[aria-pressed='true'] {
      color: ${theme.palette.success.main};
      pointer-events: none;
      user-select: none;
      outline-color: ${theme.palette.success.main};
      border-color: ${theme.palette.success.main};
    }
`,
);

const MarketplaceLogoStyled = styled('img')(
  ({ theme }) => `
    margin-inline: ${theme.spacing(2)};
    text-indent: -100vw;
    height: 30px;
    overflow: hidden;
`,
);

type _PaymentProviderName<T extends string> = T extends `suger_${infer S}` ? S : T;

export type PaymentProviderName = _PaymentProviderName<
  Extract<PaymentInformation['type_'], 'suger_aws' | 'suger_gcp' | 'suger_azure' | 'stripe'>
>;

export const PaymentProvidersMarketplace = typedMemo(function PaymentProvidersMarketplace<
  T extends PaymentProviderName,
>({ provider, onClick, disabled }: { provider: T; onClick?: (provider: T) => void; disabled?: boolean }) {
  const active = typeof onClick !== 'function';
  const [loaded, setLoaded] = useState(false);

  return (
    <StyledProviderButtonLike
      ariaLabel={`Setup payment with ${capitalize(provider)}`}
      disabled={disabled}
      pressed={!onClick}
      onClick={() => {
        if (!active) {
          onClick(provider);
        }
      }}
    >
      {active && <CheckIcon color="success" sx={{ ml: 1, mr: loaded ? -1 : 1 }} fontSize="small" />}
      <MarketplaceLogoStyled
        src={provider === 'stripe' ? '/stripe_logo.svg' : `/${provider}_marketplace_logo.svg`}
        alt={capitalize(provider)}
        sx={{ aspectRatio: provider === 'stripe' ? '300 / 143' : '100 / 21' }}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </StyledProviderButtonLike>
  );
});

export function PaymentProviders({
  accountId,
  marketplaces = ['stripe', 'aws', 'gcp', 'azure'],
}: {
  accountId: string;
  marketplaces?: PaymentProviderName[] | undefined;
}) {
  const createMarketplaceRedirect = useCreateMarketplaceRedirect();
  const createSessionAndRedirect = useCreateStripeSession({ accountId, shouldRedirect: true });

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="stripe"
          disabled={!marketplaces.includes('stripe')}
          onClick={createSessionAndRedirect}
        />
      </Grid>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="aws"
          disabled={!marketplaces.includes('aws')}
          onClick={createMarketplaceRedirect}
        />
      </Grid>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="gcp"
          disabled={!marketplaces.includes('gcp')}
          onClick={createMarketplaceRedirect}
        />
      </Grid>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="azure"
          disabled={!marketplaces.includes('azure')}
          onClick={createMarketplaceRedirect}
        />
      </Grid>
    </Grid>
  );
}

export function PaymentProvidersNew({
  accountId,
  marketplaces = ['stripe', 'aws', 'gcp', 'azure'],
  onlyValid = false,
}: {
  accountId: string;
  marketplaces?: PaymentProviderName[] | undefined;
  onlyValid?: boolean;
}) {
  const { data: validPaymentMethods, isLoading } = useGetValidPaymentMethodsQuery({ account_id: accountId });
  const [changePaymentMethod] = useChangePaymentMethodMutation();
  const createMarketplaceRedirect = useCreateMarketplaceRedirect();
  const createSessionAndRedirect = useCreateStripeSession({ accountId, shouldRedirect: true });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<PaymentProviderName | null>(null);

  const handleChangePaymentMethod = (provider: PaymentProviderName) => {
    setSelectedProvider(provider);
    setOpenDialog(true);
  };

  const handleConfirmChangePaymentMethod = async () => {
    if (!selectedProvider) {
      return;
    }
    const existingMethod = validPaymentMethods?.find(
      (method) => method.type_ === `suger_${selectedProvider}` || method.type_ === selectedProvider,
    );
    if (!existingMethod) {
      return;
    }

    await changePaymentMethod({
      account_id: accountId,
      payment_information_id: existingMethod.id,
    });
    setOpenDialog(false);
  };

  const handleProviderAction = (provider: PaymentProviderName) => {
    const existingMethod = validPaymentMethods?.find(
      (method) => method.type_ === `suger_${provider}` || method.type_ === provider,
    );

    if (existingMethod) {
      handleChangePaymentMethod(provider);
    } else {
      // Create new payment method
      if (provider === 'stripe') {
        void createSessionAndRedirect();
      } else {
        void createMarketplaceRedirect(provider);
      }
    }
  };

  if (isLoading) {
    return <Skeleton>Loading payment methods...</Skeleton>;
  }

  /**
   * Logic:
   * - If 'onlyValid' is true (meaning the user has already selected a payment method) and
   *   there is at least one valid cloud provider payment method:
   *   - We show only the marketplaces corresponding to those valid methods.
   * - If 'onlyValid' is true but there are no valid cloud provider payment methods (i.e.,
   *   the user has configured only Stripe and the cloud provider is yet to be configured):
   *   - We show all the available cloud providers.
   * - If 'onlyValid' is false (meaning the user hasn't configured any payment methods):
   *   - We include all marketplaces.
   */
  const hasSuger = onlyValid && validPaymentMethods?.some((method) => method.type_.startsWith('suger'));
  const availableMarketplaces = hasSuger
    ? marketplaces.filter((provider) =>
        validPaymentMethods?.some((method) => method.type_ === `suger_${provider}` || method.type_ === provider),
      )
    : marketplaces;

  return (
    <>
      <Grid container={true} spacing={2}>
        {availableMarketplaces.map((provider) => (
          <Grid item={true} key={provider}>
            <PaymentProvidersMarketplace
              provider={provider}
              onClick={() => handleProviderAction(provider)}
              disabled={false}
            />
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Payment Method Change</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change your payment method to {capitalize(selectedProvider || '')}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChangePaymentMethod} color="primary" variant="contained" autoFocus={true}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
