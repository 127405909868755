import { Alert, MenuItem, Select } from '@mui/material';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useClusterConfig, useClusterConfigUpdater, useDefaultClusterConfig } from './ClusterConfigurationContext';
import { QdrantConfigurationSchema } from './QdrantConfigurationContext';
import { useAccessControl } from '../../../../hooks/useAccessControl';

export const MIN_NODES = 1;
export const MAX_NODES = 20;

export const ClusterNodesControl = ({ disabled }: { disabled?: boolean }) => {
  const { nodes } = useClusterConfig();
  let { nodes: defaultNodes } = useDefaultClusterConfig();
  const { match: hasOperatorV2Privilege } = useAccessControl({ privileges: ['OPERATOR_V2'] });
  // horizontal downscaling allowed
  if (hasOperatorV2Privilege) {
    defaultNodes = 1;
  }

  const { getValues } = useFormContext<QdrantConfigurationSchema>();
  const replicationFactor = getValues('collection.replication_factor');
  const haEnforced = Boolean(replicationFactor && replicationFactor > 1);
  const startValue = Math.max(MIN_NODES, defaultNodes ?? 0, haEnforced ? 2 : 0);

  return <ClusterNodesControlInner startValue={startValue} value={nodes} haEnforced={haEnforced} disabled={disabled} />;
};

const ClusterNodesControlInner = memo(function ClusterNodesControlInner({
  value,
  startValue,
  haEnforced,
  disabled,
}: {
  value: number;
  startValue: number;
  haEnforced: boolean;
  disabled?: boolean;
}) {
  const menuItems = Array.from({ length: MAX_NODES }, (_, i) => i + startValue);
  const update = useClusterConfigUpdater();

  return (
    <>
      <Select<number>
        value={value}
        variant="outlined"
        onChange={(event) => {
          const value = event.target.value as number;
          update({ type: 'nodes', value });
        }}
        disabled={disabled}
        labelId="cluster-advanced-configuration-nodes-label"
      >
        {menuItems.map((value) => (
          <MenuItem value={value} key={value}>
            {`${value} node${value > 1 ? 's' : ''}`}
          </MenuItem>
        ))}
      </Select>
      {haEnforced && value < 3 && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          Configuring less than 3 nodes is not fully highly available. If one node fails, your cluster will be in a
          degraded state. You will still be able to read and write data, but not make configuration changes.
        </Alert>
      )}
    </>
  );
});
