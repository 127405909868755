import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from './_account-dashboard';
import { Invites } from '../components/Access/Invites';
import { ProgrammaticAccessKeys } from '../components/Access/ProgrammaticAccessKeys';
import { Users } from '../components/Access/Users';
import { AccountDashboardMain } from '../components/AccountDashboard/AccountDashboardMain';
import { useRouteAccessControl } from '../router/useRouteAccessControl';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cloud UI Access',
    },
  ],
  staticData: {
    privileges: ['PROGRAMMATIC_ACCESS'],
  },
  getParentRoute: () => AccountDashboardRoute,
  path: 'cloud-access',
  component: DataAccessComponent,
});

function DataAccessComponent() {
  const { match: hasProgrammaticAccessPrivilege } = useRouteAccessControl(Route);

  return (
    <AccountDashboardMain title="Cloud UI Access">
      <Box sx={{ marginTop: 3 }}>
        <Invites sx={{ mb: 3, mt: 3 }} />
        <Users />
        {hasProgrammaticAccessPrivilege && <ProgrammaticAccessKeys />}
      </Box>
    </AccountDashboardMain>
  );
}
