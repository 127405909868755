import { ErrorComponentProps } from '@tanstack/react-router';
import { ComponentType } from 'react';
import { DefaultErrorComponent } from '../components/Common/DefaultErrorComponent';
import { captureReactException, isModuleNotFoundError } from '../utils/error-utils';

export const createRouteErrorComponent =
  (ErrorComponent?: ComponentType<ErrorComponentProps>) =>
  ({ error, info, reset }: ErrorComponentProps) => {
    let _eventId = '';
    if (!isModuleNotFoundError(error)) {
      /**
       * Note: starting React 19, there will be specific error hooks to capture React errors.
       * @see https://docs.sentry.io/platforms/javascript/guides/react/#react-19-error-reporting
       */
      _eventId = captureReactException(error, info, { mechanism: { handled: true } });
    }

    return (
      <DefaultErrorComponent error={error}>
        {ErrorComponent ? <ErrorComponent error={error} info={info} reset={reset} /> : null}
      </DefaultErrorComponent>
    );
  };
