import createClient, {
  Middleware,
  MaybeOptionalInit,
  ParamsOption,
  ParseAsResponse,
  RequestOptions,
} from 'openapi-fetch';
import { HttpMethod, MediaType, PathsWithMethod, ResponseObjectMap, SuccessResponse } from 'openapi-typescript-helpers';
import { paths } from '../api-schema';
import { ComponentSchema } from '../utils/api-schema-utils';

export const client = createClient<paths>({ baseUrl: '/api/v1' });

/**
 * Middleware to add Authorization header to all requests.
 * The 'token' is set externally by the app.
 */
export const authMiddleware: Middleware & { token: string } = {
  token: '',
  onRequest({ request }) {
    if (this.token) {
      request.headers.set('Authorization', `Bearer ${this.token}`);
      return request;
    }
  },
  async onResponse({ response }) {
    if (response.status === 401) {
      // Redirect to logout page, which in turn redirects to login page
      let error: string | object = await response.clone().text();
      try {
        error = JSON.parse(error) as object; // attempt to parse as JSON
      } catch {
        // noop
      }
      // The redirect
      const logoutUrl = new URL('/logout');
      try {
        const { detail: maybeAuthorizeUrl } = error as { detail: string };
        logoutUrl.search = new URL(maybeAuthorizeUrl).search;
      } catch {
        // noop
      }
      // Note: instead of router.buildAndCommitLocation() which provides proper router typing,
      // we have to redirect the traditional way because of circular dependency.
      location.assign(logoutUrl);
    }
  },
};

client.use(authMiddleware);

const throwOnErrorMiddleware: Middleware = {
  async onResponse({ response }) {
    if (!response.ok) {
      let error: string | object = await response.clone().text();
      try {
        error = JSON.parse(error) as object; // attempt to parse as JSON
      } catch {
        // noop
      }
      throw new ApiClientError(error, response.status);
    }
  },
};

client.use(throwOnErrorMiddleware);

export class ApiClientError extends Error {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
    public error: Object,
    readonly originalStatus: number,
  ) {
    super();
    this.name = 'ApiClientError';
  }

  override get message() {
    const data = typeof this.error === 'string' ? this.error : JSON.stringify(this.error);
    return `(HTTP ${this.originalStatus}) <${data}>`;
  }
}

export class FetchTypeError extends Error {
  constructor(public error: Error) {
    super(error.message, { cause: error });
    this.name = 'FetchTypeError';
  }
}

export function isApiValidationError<TDetail extends ReadonlyArray<ComponentSchema<'ValidationError'>>>(
  error: unknown,
): error is { detail: TDetail } {
  if (isApiClientError(error)) {
    const { error: e } = error;
    if ('detail' in e && Array.isArray(e.detail)) {
      const val = e.detail as TDetail[number][];
      return val.every((v) => 'type' in v && 'msg' in v && 'loc' in v);
    }
  }
  return false;
}

export function isApiCodeError(error: unknown): error is { detail: ComponentSchema<'QdrantErrorCode'> } {
  if (isApiClientError(error)) {
    const { error: e } = error;
    return 'detail' in e && typeof e.detail === 'string' && /^E(\d{4})$/.test(e.detail);
  }
  return false;
}

export function isApiClientError(error: unknown): error is ApiClientError {
  return isApiClientErrorRaw(error) && typeof error.error === 'object' && 'detail' in error.error;
}

export function isApiClientErrorRaw(error: unknown): error is ApiClientError {
  return error instanceof ApiClientError;
}

export type QueryData<
  Path extends PathsWithMethod<paths, Method>,
  Method extends HttpMethod = 'get',
  Media extends MediaType = MediaType,
> = ParseAsResponse<
  SuccessResponse<ResponseObjectMap<paths[Path][Method]>, Media>,
  MaybeOptionalInit<paths[Path], Method>
>;

export type QueryOptions<Path extends PathsWithMethod<paths, Method>, Method extends HttpMethod = 'get'> = ParamsOption<
  paths[Path][Method]
>;

export type MutationOptions<
  Path extends PathsWithMethod<paths, Method>,
  Method extends HttpMethod = 'post',
> = RequestOptions<paths[Path][Method]>;

export type FromPathParams<T> = T extends {
  params: {
    path: Record<string, unknown>;
  };
}
  ? T['params']['path']
  : never;

export function toPathParams<TParams extends Record<string, unknown>>(
  params: TParams,
): {
  params: { path: TParams };
};
export function toPathParams<TParams extends Record<string, unknown>, TKey extends keyof TParams>(
  params: TParams,
  ...filterKeys: TKey[]
): {
  params: { path: Pick<TParams, TKey> };
};
export function toPathParams<TParams extends Record<string, unknown>, TKey extends keyof TParams>(
  params: TParams,
  ...filterKeys: TKey[]
) {
  if (filterKeys.length === 0) {
    return { params: { path: params } };
  }
  return {
    params: {
      path: Object.fromEntries(Object.entries(params).filter(([key]) => filterKeys.includes(key as TKey))),
    },
  };
}
