import { Upgrade } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  MenuItem,
  TextField,
  Tooltip,
  Zoom,
  ListItemText,
  CircularProgress,
  styled,
} from '@mui/material';
import { compare, validate } from 'compare-versions';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { Cluster, useGetClustersVersionsQuery, useUpdateClusterVersionMutation } from '../../../services/clustersApi';
import { isFetchMutationError } from '../../../services/helpers';
import { getUserMessage } from '../../../utils/errorHandlingUtils';
import { ButtonDangerAction } from '../../Common/ButtonDangerAction';
import { SeverityPill } from '../../Common/SeverityPill';

const StyledTextField = styled(TextField)(
  ({ theme }) => `
    margin: ${theme.spacing(1)};
    min-width: 160px;
    & .Mui-disabled {
      & .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
        color: ${theme.palette.text.primary};
        -webkit-text-fill-color: ${theme.palette.text.primary};
        border: 0;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: 1px dashed ${theme.palette.action.disabled};
      }
    }
  `,
);

export const ClusterVersionUpdate = memo(
  ({ accountId, cluster, label }: { accountId: string; cluster?: Cluster; label: boolean }) => {
    const [latestVersion, setLatestVersion] = useState<string>('latest');
    const [currentVersion, setCurrentVersion] = useState(cluster?.state?.version || 'UNKNOWN');
    const [wantedVersion, setWantedVersion] = useState(currentVersion);
    const { enqueueSnackbar } = useSnackbar();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    // Update version when cluster updates
    useEffect(() => {
      const updatedVersion = cluster?.state?.version ?? 'UNKNOWN';
      setCurrentVersion(updatedVersion);
      setWantedVersion(updatedVersion);
    }, [cluster]);

    useEffect(() => {
      if (!cluster?.state?.nodes) {
        setIsUpdating(false);
        return;
      }
      const nodes = cluster.state.nodes;
      const nodeIds = Object.keys(nodes);
      const hasOutdatedNode = nodeIds.some((nodeId) => nodes[nodeId] && nodes[nodeId]?.version !== currentVersion);
      setIsUpdating(hasOutdatedNode);
    }, [cluster, isUpdating, currentVersion]);

    const [updateClusterVersion, { isLoading: isUpdateVersionLoading }] = useUpdateClusterVersionMutation();

    const {
      data: versions,
      error: errorFetchingVersions,
      isLoading: isLoadingVersions,
    } = useGetClustersVersionsQuery({});
    useEffect(() => {
      if (!isLoadingVersions && !errorFetchingVersions && versions?.length) {
        setLatestVersion(versions[0].version);
      }
    }, [versions, errorFetchingVersions, isLoadingVersions]);

    const upgradeAvailable =
      validate(latestVersion) && validate(currentVersion) && compare(currentVersion, latestVersion, '<');

    const handleTextFieldClick = (_event: unknown) => {
      // do not let user open select if there is no other versions to select
      // and show tooltip with info
      if (currentVersion === latestVersion || isUpdating) {
        // e.stopPropagation();
        setTooltipOpen(true);
      }
    };

    const handleUpdate = useCallback(
      async (newVersion: string) => {
        if (!cluster) {
          return;
        }
        const result = await updateClusterVersion({
          clusterId: cluster.id,
          accountId,
          version: newVersion,
          rolling: true,
        });

        if (isFetchMutationError(result)) {
          enqueueSnackbar(getUserMessage(result.error), { variant: 'error' });
        } else {
          enqueueSnackbar('Cluster version update triggered.', { variant: 'success' });
        }
      },
      [cluster, accountId, updateClusterVersion, enqueueSnackbar],
    );

    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          m: -1,
        }}
      >
        {!isUpdating && upgradeAvailable && <SeverityPill color="warning">Upgrade available</SeverityPill>}

        <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            componentsProps={{ tooltip: { style: { top: '-30px' } } }}
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            title={isUpdating ? 'Version updating is in progress' : "You're already on the latest version"}
            leaveDelay={500}
            TransitionComponent={Zoom}
            arrow={true}
          >
            <StyledTextField
              defaultValue={currentVersion}
              value={wantedVersion}
              label={label ? 'Version' : null}
              select={true}
              size="small"
              onChange={(e) => setWantedVersion(e.target.value)}
              onClick={handleTextFieldClick}
              disabled={currentVersion === latestVersion || !cluster?.state || isUpdating}
            >
              {/*current version*/}
              <MenuItem value={currentVersion}>
                <ListItemText sx={{ mt: 0, mb: '1px' }}>
                  <span>{currentVersion}</span>
                  {isUpdating && <CircularProgress size={14} sx={{ ml: 2 }} />}
                </ListItemText>
              </MenuItem>

              {/*latest version*/}
              {currentVersion !== latestVersion && (
                <MenuItem value={latestVersion}>
                  <ListItemText sx={{ my: 0, mb: '1px' }}>{latestVersion}</ListItemText>
                </MenuItem>
              )}
            </StyledTextField>
          </Tooltip>
        </ClickAwayListener>

        <ButtonDangerAction
          manualConfirmation={true}
          isLoading={isUpdateVersionLoading}
          actionName={'Update'}
          loadingStateName={'Update'}
          handleDangerAction={() => handleUpdate('latest')}
          buttonIcon={<Upgrade fontSize="small" />}
          targetName={cluster?.name ?? ''}
          dialogTitle={'Updating version'}
          dialogContent={`Updating cluster ${cluster?.name ?? ''} to Qdrant version: ${wantedVersion}`}
          dialogWarning={'This action may cause a short downtime.'}
          disabled={currentVersion === wantedVersion || isUpdating || !cluster?.state?.actionable}
        />
      </Box>
    );
  },
);
