import { z, ZodType } from 'zod';
import { Package } from '../../services/bookingApi';
import { CloudRegion } from '../../services/clustersApi';
import { getDefaultRegion } from '../Clusters/ClusterSetup/ClusterRegionList';
import { toPackageResourcesObject } from '../Clusters/ClusterSetup/helpers';
import { CLOUD_PROVIDER_MAP, CLOUD_PROVIDERS } from '../Clusters/constants';
import { transformBytesToGigabytes } from '../Clusters/helpers';

const SCALAR_QUANTIZATION_DIVIDER = 4;
const BINARY_QUANTIZATION_DIVIDER = 32;
const PRODUCT_QUANTIZATION_DIVIDER = 64;

export const QUANTIZATIONS = ['Scalar', 'Product', 'Binary', 'None'] as const;
export type Quantization = (typeof QUANTIZATIONS)[number];
export const DEFAULT_QUANTIZATION: Quantization = 'None';

type PackageWithExtractedResources = Package & { memoryResource?: number; cpuResource?: number };

const findPackageWithMostRAMAndLeastCPU = (packages: Package[]): Package | undefined => {
  let maxRamAmount = 0;
  let packageCPU: number | undefined;
  let packageWithMostRAM: Package | undefined = undefined;

  packages.forEach((pkg) => {
    const { memory: ramAmount = 0, cpu: cpuAmount = 0 } = toPackageResourcesObject(pkg);
    // Find the package with the most RAM and the lowest CPU
    if (ramAmount > maxRamAmount || (ramAmount === maxRamAmount && packageCPU && cpuAmount < packageCPU)) {
      maxRamAmount = ramAmount;
      packageWithMostRAM = pkg;
      packageCPU = cpuAmount;
    }
  });

  return packageWithMostRAM;
};

export const getPackageByMemory = (
  memory?: number,
  packages?: Package[],
): PackageWithExtractedResources | undefined => {
  if (!packages || !memory) {
    return undefined;
  }

  // Filter packages based on those that have a memory resource configuration matching or exceeding the required memory
  const suitablePackages = packages.reduce<PackageWithExtractedResources[]>((acc, pkg) => {
    const memoryResource = pkg.resource_configuration.find(
      (rc) => rc.resource_option?.resource_type === 'ram' && rc.amount >= memory,
    )?.amount;
    const cpuResource = pkg.resource_configuration.find((rc) => rc.resource_option?.resource_type === 'cpu')?.amount;

    // Only packages with defined memoryResource and cpuResource are suitable and thus, included
    if (memoryResource !== undefined && cpuResource !== undefined) {
      acc.push({
        ...pkg,
        memoryResource,
        cpuResource,
      });
    }

    return acc;
  }, []);

  // If no suitable package was found it means the required memory exceeds the maximum memory available in the packages.
  // Return the package with the highest memory available
  if (suitablePackages.length === 0) {
    return findPackageWithMostRAMAndLeastCPU(packages);
  }

  // Group packages by memoryResource (to later find the package with the lowest CPU in each group)
  const groupedByMemory = suitablePackages.reduce<Record<number, PackageWithExtractedResources[]>>((groups, pkg) => {
    const ramGroup = pkg.memoryResource!;
    // Typescript fails to understand that a given group can be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!groups[ramGroup]) {
      groups[ramGroup] = [];
    }
    groups[ramGroup].push(pkg);
    return groups;
  }, {});

  // For each group, find the package with the lowest CPU
  const lowestCPUPackages = Object.values(groupedByMemory).map((group) =>
    // All packages within groupedByMemory have cpuResource defined due to the filtering
    // First package is taken as the initial lowest CPU package by default.
    group.reduce((lowest, pkg) => (pkg.cpuResource! < lowest.cpuResource! ? pkg : lowest)),
  );

  // Sort by memoryResource to prioritize lower memory usage
  lowestCPUPackages.sort((a, b) => a.memoryResource! - b.memoryResource!);

  return lowestCPUPackages[0] || undefined;
};

export const getHighestMemoryAvailable = (packages: Package[]): number => {
  if (!packages.length) {
    return 1;
  }

  const memoryAmounts = packages.map(
    (pkg) =>
      pkg.resource_configuration.find((resourceConfig) => resourceConfig.resource_option?.resource_type === 'ram')
        ?.amount ?? 0,
  );
  return Math.max(...memoryAmounts, 1);
};

const VECTOR_SIZE_IN_BYTES = 4;
const METADATA_AND_OPTIMIZATION_MULTIPLIER = 1.5;
const STORAGE_OPTIMIZED_MULTIPLIER = 0.35;
/**
 * Calculates the minimum required memory for a Qdrant cluster based on the provided
 * configuration (vectors, dimensions and if it's storage optimized).
 * See https://qdrant.tech/documentation/guides/capacity-planning/#basic-configuration for more details.
 */
export const getMinimumRequiredMemory = ({
  vectors,
  dimension,
  storageOptimized,
  quantization,
}: {
  vectors: number;
  dimension: number;
  storageOptimized?: boolean;
  quantization: Quantization;
}) => {
  // let calculatedMemory = replicas * ((vectors * dimension * 4 * 1.5) / BYTES_IN_GIGABYTES);
  // Extra 50% is needed for metadata (indexes, point versions, etc.) as well as for temporary
  // segments constructed during the optimization process.
  let calculatedMemoryInGb = transformBytesToGigabytes(
    vectors * dimension * VECTOR_SIZE_IN_BYTES * METADATA_AND_OPTIMIZATION_MULTIPLIER,
  );

  if (storageOptimized) {
    calculatedMemoryInGb *= STORAGE_OPTIMIZED_MULTIPLIER;
  }

  switch (quantization) {
    case 'Scalar':
      calculatedMemoryInGb /= SCALAR_QUANTIZATION_DIVIDER;
      break;
    case 'Product':
      calculatedMemoryInGb /= PRODUCT_QUANTIZATION_DIVIDER;
      break;
    case 'Binary':
      calculatedMemoryInGb /= BINARY_QUANTIZATION_DIVIDER;
      break;
    default:
      break;
  }

  return Math.ceil(calculatedMemoryInGb);
};

export const MAX_VECTORS = 100_000_000_000;
export const MAX_DIMENSIONS = 65_536;
export const MAX_REPLICAS = 100;
export const DEFAULT_CALCULATOR_PROVIDER = CLOUD_PROVIDER_MAP.AWS;

const getTransformFunc = (maxValue: number) => (value: number) => {
  if (value < 0) {
    return 0;
  }

  if (value > maxValue) {
    return maxValue;
  }

  return value;
};

export const calculatorSchema = z.object({
  vectors: z.coerce
    .number()
    .int('Vectors must be an integer')
    .positive('Vectors must be positive')
    .max(MAX_VECTORS)
    .transform(getTransformFunc(MAX_VECTORS)),
  dimension: z.coerce
    .number()
    .int('Dimension must be an integer')
    .positive('Dimension must be positive')
    .max(MAX_DIMENSIONS)
    .transform(getTransformFunc(MAX_DIMENSIONS)),
  replicas: z.coerce
    .number()
    .int('Replicas must be an integer')
    .positive('Replicas must be positive')
    .min(1)
    .max(MAX_REPLICAS)
    .transform(getTransformFunc(MAX_REPLICAS)),
  quantization: z.enum(QUANTIZATIONS, { message: 'Invalid quantization' }),
  storageOptimized: z.boolean(),
});

function fallback<T>(value: T): ZodType<T> {
  return z.any().transform(() => value);
}
export const calculatorSearchSchema = z
  .object({
    vectors: z.coerce
      .number()
      .int()
      .min(0)
      .optional()
      .or(fallback(0))
      .pipe(z.number().max(MAX_VECTORS).optional().or(fallback(MAX_VECTORS))),
    dimension: z.coerce
      .number()
      .int()
      .min(0)
      .optional()
      .or(fallback(0))
      .pipe(z.number().max(MAX_DIMENSIONS).optional().or(fallback(MAX_DIMENSIONS))),
    storageOptimized: z.coerce.boolean().or(fallback(false)),
    replicas: z.coerce
      .number()
      .int()
      .min(1)
      .optional()
      .or(fallback(1))
      .pipe(z.number().max(MAX_REPLICAS).optional().or(fallback(MAX_REPLICAS))),
    provider: z.enum(CLOUD_PROVIDERS).or(fallback(DEFAULT_CALCULATOR_PROVIDER)),
    region: z
      .string()
      .or(fallback(undefined))
      .transform((region) => region as CloudRegion),
    quantization: z.enum(QUANTIZATIONS).or(fallback(DEFAULT_QUANTIZATION)),
  })
  .transform(({ provider, region, ...rest }) => {
    const result = {
      provider,
      region: getDefaultRegion(provider, region),
      ...rest,
    };
    return result;
  });

export function calculatorCreateSearch<T extends Partial<z.infer<typeof calculatorSearchSchema>>>(params: T) {
  return calculatorSearchSchema.parse(params);
}
