import { Typography } from '@mui/material';
import { PaymentConnectedMarketplace } from './PaymentConnectedMarketplace';
import { PaymentConnectedStripe } from './PaymentConnectedStripe';
import { PaymentInformation } from '../../services/paymentApi';

export const PaymentConnected = ({
  accountId,
  paymentInformation,
}: {
  accountId: string;
  paymentInformation: PaymentInformation;
}) => {
  switch (paymentInformation.type_) {
    case 'suger_aws':
      return <PaymentConnectedMarketplace provider="aws" marketplaceAccountId={paymentInformation.aws_account_id} />;
    case 'suger_gcp':
      return <PaymentConnectedMarketplace provider="gcp" marketplaceAccountId={paymentInformation.gcp_account_id} />;
    case 'suger_azure':
      return (
        <PaymentConnectedMarketplace provider="azure" marketplaceAccountId={paymentInformation.azure_account_id} />
      );
    case 'custom':
      return <Typography variant="body2">Billed based on custom rates.</Typography>;
    case 'stripe':
      return paymentInformation.payment_method_id ? (
        <PaymentConnectedStripe accountId={accountId} paymentInformationId={paymentInformation.id} />
      ) : null;
    default:
      return null;
  }
};
